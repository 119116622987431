import { Routes } from '@angular/router';
import { HomeComponent } from './components/pages/home/home.component';
import { DashboardComponent } from './components/pages/dashboard/dashboard.component';
import { ViewFilesComponent } from './components/pages/view-files/view-files.component';
import { UserTeamComponent } from './components/pages/user-team/user-team.component';
import { AnalyticsComponent } from './components/pages/analytics/analytics.component';
import { UserSetsComponent } from './components/pages/user-sets/user-sets.component';
import { UserGuideComponent } from './components/pages/user-guide/user-guide.component';
import { ViewerComponent } from './components/pages/viewer/viewer.component';
import { UnauthorizedAccessComponent } from './components/authentication/unauthorized-access/unauthorized-access.component';
import { AuthGuard } from './services/auth.service';
import { TeamManagementComponent } from './components/pages/team-management/team-management.component';
import { ClientComponent } from './components/pages/team-management/client/client.component';
import { InviteUserComponent } from './components/pages/team-management/invite-user/invite-user.component';
import { InviteFreeTrialComponent } from './components/pages/team-management/invite-free-trial/invite-free-trial.component';
import { UserRoleMappingComponent } from './components/pages/team-management/user-role-mapping/user-role-mapping.component';
import { ResetPasswordComponent } from './components/authentication/reset-password/reset-password/reset-password.component';
import { SwaggerUIComponent } from './components/swagger-ui/swagger-ui.component';

export const routes: Routes = [
    {
        path: 'unauthorizedaccess', component: UnauthorizedAccessComponent
    },
    
    {
        path: 'reset-password', component: ResetPasswordComponent,
    },
    {
        path: 'api-docs', component: SwaggerUIComponent
    },
    {
        path: '',
        component: HomeComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: 'home', component: DashboardComponent
            },
            { path: 'viewer/:fileID', component: ViewerComponent },
            {
                path: 'files', component: ViewFilesComponent

            },
            {
                path: 'myteam', component: UserTeamComponent
            },
            {
                path: 'manage-team',
                children: [
                    {
                        path: '', component: TeamManagementComponent,
                    },
                    {
                        path: 'client', component: ClientComponent
                    },
                    {
                        path: 'invite-user', component: InviteUserComponent
                    },
                    {
                        path: 'invite-free-trial', component: InviteFreeTrialComponent
                    }
                    ,
                    {
                        path: 'user-role', component: UserRoleMappingComponent
                    }]
            },
            {
                path: 'analytics', component: AnalyticsComponent
            },
            {
                path: 'mysets', component: UserSetsComponent
            },
            {
                path: 'userguide', component: UserGuideComponent
            },
            
            {
                path: '', redirectTo: 'home', pathMatch: 'full'
            }
        ]

    }
];
