import { Component, OnInit } from '@angular/core';
import { ProfileService } from '../../../services/profile.service';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '../../../../environments/environment';
import { UserDetails } from '../../../models/user-details.model';

@Component({
  selector: 'app-profile',
  standalone: true,
  imports: [],
  templateUrl: './profile.component.html',
  styleUrl: './profile.component.css'
})
export class ProfileComponent implements OnInit {

  isImageLoading: boolean = false;
  imageToShow: any;
  userDetails: UserDetails = {};

  constructor(
    private _profileService: ProfileService,
    private domSanitizer: DomSanitizer
  ) {
  }

  ngOnInit() {
    // To get user's photo
    //this.getProfilePicture();
    this.getUserDetails();

  }

  getUserDetails() {
    this._profileService.getLoggedInUserInfo().subscribe(userInfo => this.userDetails = userInfo);
  }

  getProfilePicture() {
    let imgURL = environment.apiConfig[0].uri + 'me/photo/$value';
    this.isImageLoading = true;
    this._profileService.getImage(imgURL).subscribe(
      (blob) => {
        this.isImageLoading = false;

        let urlCreator = window.URL || window.webkitURL;
        this.imageToShow = this.domSanitizer.bypassSecurityTrustUrl(
          urlCreator.createObjectURL(blob)
        );
      },
      (error) => {
        this.isImageLoading = false;
        console.log(error);
      }
    );
  }

  createImageFromBlob(image: Blob) {
    let reader = new FileReader();
    reader.addEventListener(
      'load',
      () => {
        console.log(reader.result);
        const imgRes: any = reader.result;
        this.imageToShow = this.domSanitizer.bypassSecurityTrustUrl(imgRes);
      },
      false
    );
    if (image) {
      reader.readAsDataURL(image);
    }
  }
}
