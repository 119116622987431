import { Component } from '@angular/core';

@Component({
  selector: 'app-user-guide',
  standalone: true,
  imports: [],
  templateUrl: './user-guide.component.html',
  styleUrl: './user-guide.component.css'
})
export class UserGuideComponent {

}
