import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavSidebarComponent } from '../../layout/menubar/nav-sidebar.component';
import { HeaderComponent } from '../../layout/header/header.component';
import { ProfileComponent } from '../../shared/profile/profile.component';
import { ActivatedRoute, NavigationEnd, NavigationError, NavigationStart, Router, RouterOutlet } from '@angular/router';
import { DashboardComponent } from '../dashboard/dashboard.component';
import { SharedService } from '../../../services/shared.service';
import { UserIdleService } from 'angular-user-idle';
import { AuthenticationService } from '../../../services/auth.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: [],
  standalone: true,
  imports: [HeaderComponent, DashboardComponent, NavSidebarComponent, RouterOutlet, CommonModule, ProfileComponent]
})
export class HomeComponent implements OnInit {
  loginDisplay = false;

  constructor(private router: Router,
    private sharedService: SharedService,
    private route: ActivatedRoute,
    private userIdle: UserIdleService,
    private authenticationService: AuthenticationService
  ) {

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        // Show loading indicator
      }

      if (event instanceof NavigationEnd) {
        this.sharedService.currentURL.next(event.url);
        console.log(event.url);
        // Hide l(oading indicator
      }

      if (event instanceof NavigationError) {
        // Hide loading indicator

        // Present error to user
        console.log(event);
      }
    });

  }

  ngOnInit(): void {

    this.userIdle.startWatching();

    // Start watching when user idle is starting.
    this.userIdle.onTimerStart().subscribe();

    // Start watch when time is up.
    this.userIdle.onTimeout().subscribe(() =>
      this.authenticationService.signOut().subscribe(() => {
        this.authenticationService.navigateToLoginRadius();
      }
      ));
  }

}
