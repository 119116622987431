import { Component, Output, EventEmitter, Input, OnChanges, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MultiSelectModule } from 'primeng/multiselect';
import { CalendarModule } from 'primeng/calendar';
import { FormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';

@Component({
  selector: 'app-filelist-filter',
  standalone: true,
  imports: [CommonModule,FormsModule, MultiSelectModule, CalendarModule,InputTextModule],
  templateUrl: './filelist-filter.component.html',
  styleUrls: ['./filelist-filter.component.css']
})
export class FilelistFilterComponent implements OnChanges {
  @Input() blobList: any[] = [];
  @Output() filtersChanged = new EventEmitter<any>();
  @Output() resetFilters = new EventEmitter<void>();

  teamMembers: any[] = [];
  statuses: any[] = [];
  fileTypes: any[] = [];

  selectedTeamMembers: any[] = [];
  selectedStatuses: any[] = [];
  selectedFileTypes: any[] = [];
  selectedDateFrom: Date | null = null;
  selectedDateTo: Date | null = null;
  fileSizeMore: number | null = null;
  fileSizeLess: number | null = null;

  filters: any = {};

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['blobList'] && this.blobList) {
      this.populateDropdowns();
    }
  }

  populateDropdowns() {
    const teamMemberSet = new Set();
    const statusSet = new Set();
    const fileTypeSet = new Set();

    this.blobList.forEach(blob => {
      teamMemberSet.add(blob.UploadedBy);
      statusSet.add(blob.Status);
      fileTypeSet.add(blob.FileType);
    });

    this.teamMembers = Array.from(teamMemberSet).map(member => ({ label: member, value: member }));
    this.statuses = Array.from(statusSet).map(status => ({ label: status, value: status }));
    this.fileTypes = Array.from(fileTypeSet).map(type => ({ label: type, value: type }));
  }

  onMultiSelectChange(filterType: string, event: any) {
    this.filters[filterType] = event.value;
  }

  onDateChange(filterType: string, date: Date) {
    this.filters[filterType] = date.toISOString().split('T')[0]; // Format date as 'yyyy-mm-dd'
  }

  onInputChange(filterType: string, event: Event) {
    const inputElement = event.target as HTMLInputElement;
    this.filters[filterType] = inputElement.value;
  }

  applyFilters() {
    this.filtersChanged.emit(this.filters);
  }

  resetAllFilters() {
    this.selectedTeamMembers = [];
    this.selectedStatuses = [];
    this.selectedFileTypes = [];
    this.selectedDateFrom = null;
    this.selectedDateTo = null;
    this.fileSizeMore = null;
    this.fileSizeLess = null;
    this.filters = {};
    this.filtersChanged.emit(this.filters);
  }
}
