import { Component } from '@angular/core';
import { FileListComponent } from '../shared/display-filelist/display-filelist.component';
import { FilelistFilterComponent } from '../shared/filelist-filter/filelist-filter.component';
import { DisplayFileComponent } from '../shared/display-image/display-file.component';

@Component({
  selector: 'app-view-files',
  standalone: true,
  imports: [FileListComponent, DisplayFileComponent, FilelistFilterComponent],
  templateUrl: './view-files.component.html',
  styleUrl: './view-files.component.css'
})
export class ViewFilesComponent {

}
