import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FileService} from '../../../services/file.service';
import { CommonModule } from '@angular/common';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { BlobDetails, RevGridDetails, OutputDetails } from '../../../models/blob-details.model';
import { SkeletonModule } from 'primeng/skeleton';
import { TableModule } from 'primeng/table';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { TooltipModule } from 'primeng/tooltip';
import { UserRole } from '../../../models/user-roles-model';
import { ProfileService } from '../../../services/profile.service';





@Component({
  selector: 'app-viewer',
  standalone: true,
  imports: [ CommonModule, SkeletonModule, TableModule, DialogModule, ButtonModule,TooltipModule ],
  templateUrl: './viewer.component.html',
  styleUrls: ['./viewer.component.css']  // Corrected here
})

export class ViewerComponent implements OnInit {
  imageUrls: SafeUrl[]=[];
  blobDetails: BlobDetails | null = null;
  revGridDetails: RevGridDetails[] ;
  imageLoading: boolean = true;
  outputDataCols: any[];
  outputRevCols: any[];

  summaryData: any[];
  revGridData: any[];
  symbologyData: any[];

  zoomScale:number=1;
  visible: boolean = false;
  columns: any[];
  tableData: any[];
  angleMultiplier:number=0;
  currentImageIndex:number=0;
  mouseDown = false;

  startX: any;
  startY: any;

  scrollLeft: any;
  scrollTop: any;
  imageWidth:number;
  ImageHeight:number;
  currentUserRole:UserRole | undefined;


  @ViewChild('parent') slider: ElementRef;

  @ViewChild("uploadedImage",{ static: false }) uploadedImage: ElementRef;
  @ViewChild("zoomSlider",{ static: false }) zoomSlider: ElementRef;
  @ViewChild("FileBox",{ static: false }) fileBox: ElementRef;

  constructor(
    private route: ActivatedRoute,
    private FileService: FileService,
    private sanitizer: DomSanitizer,
    private renderer:Renderer2,
    private profileService:ProfileService
  ) {}

  ngOnInit(): void {

    this.profileService.getLoggedInUserInfo().subscribe(userInfo => {
      this.currentUserRole = userInfo?.currentRole
    });
    this.route.params.subscribe(params => {
      const fileID = params['fileID'];

      this.fetchBlobData(fileID);
      this.fetchRevGrid(fileID);
    });


    // cols displayed
    this.outputDataCols = [
      { field: 'Title', header: 'Title' },
      { field: 'Drawnum', header: 'Drawing No.' },
      { field: 'Projectnum', header: 'Project No.' },
      { field: 'TextTag', header: 'TextTag' }
    ]

    // cols displayed
    this.outputRevCols = [
      { field: 'Approved', header: 'Approved' },
      { field: 'By', header: 'By' },
      { field: 'Checked', header: 'Checked' },
      { field: 'Date', header: 'Date' },
      { field: 'Description', header: 'Description' },
      { field: 'Eng', header: 'Eng' },
      { field: 'FileID', header: 'FileID' },
      { field: 'Project', header: 'Project' },
      { field: 'Rev', header: 'Rev' },
    ]
  }

  fetchImage(fileID: string): void {
    this.FileService.fetchImage(fileID).subscribe(blob => {
      const url = URL.createObjectURL(blob);
      this.imageUrls[0]= this.sanitizer.bypassSecurityTrustUrl(url);
      this.imageLoading = false;
     this.imageWidth= this.uploadedImage.nativeElement.width;
     this.ImageHeight=this.uploadedImage.nativeElement.height;
    });
  }
  fetchProcessedImage(fileID: string): void {
    this.FileService.fetchProcessedImage(fileID).subscribe(blob => {
      const url = URL.createObjectURL(blob);
      this.imageUrls[1] = this.sanitizer.bypassSecurityTrustUrl(url);
      console.log(this.imageUrls[1])
    });
  }

  changeImage(index:number)
  {
    this.currentImageIndex+=index;

  }



  formatAssetInfo(): void {
    this.tableData = []; // Initialize tableData array

    this.columns = [
      { header: '', field: 'id' },
      { header: 'Drawing No.', field: 'drawing_number'},
      { header: 'Type', field: 'tag_type' },
      { header: 'Equipment Class', field: 'equipment_class'},
      { header: 'Description', field: 'description'},
      { header: 'Commodity Code', field: 'commodity_code'},
      { header: 'Sequence No.', field: 'sequence_number' }
    ];

    if (!this.blobDetails?.Output) {
      console.error('Blob details or output are null or undefined.');
      return;
    }
    const output: OutputDetails = this.blobDetails.Output;

    if (!output) {
      return;
    }

    // List of all TagDetails arrays in OutputDetails
    const tagArrays: (keyof OutputDetails)[] = [
      'CircleTag',
      'RectangleTag',
      'PentagonTag',
      'HexagonTag',
      'TextTag',
      'LineTag',
      'OvalTag',
      'DCS_Tag'
    ];
    for (const key of tagArrays) {
      const tagArray = output[key];
      if (Array.isArray(tagArray)) {
        for (const tagDetail of tagArray) {
          let type:any = key;
          type = this.FileService.mapTag(type, tagDetail)
          let dataObject = {
            id: null,
            drawing_number: output.Drawnum,
            tag_type: type,
            equipment_class: tagDetail[2],
            description: tagDetail[3],
            commodity_code: tagDetail[0],
            sequence_number: tagDetail[1]
          };
          this.tableData.push(dataObject)
        }
      }
    }

   this.FileService.sortTable(this.tableData)
    let count = 1
    this.tableData.forEach(element => {
      element.id = count
      count++
    });

    console.log("tableData:", this.tableData);
  }



  fetchBlobData(fileID: string) {
    this.FileService.fetchBlobData(fileID).subscribe(data => {
      this.blobDetails= data;
      this.fetchImage(fileID);
      this.fetchProcessedImage(fileID);
      console.log("Blob details fetched:", this.blobDetails);
      this.formatAssetInfo();
    }, error => {
      console.error("Error fetching blob details:", error);
    });
 }


 fetchRevGrid(fileID: string){
  this.FileService.fetchRevGrid(fileID).subscribe(data=>{
    this.revGridDetails = data;
  })
}

 changeZoom(incrementValue:number)
 {

  this.zoomScale+=incrementValue;
  this.zoomImage(false);
 }

 SetZoom(zoomValue:number)
 {

  this.zoomScale=zoomValue;
  this.zoomImage(false);
 }

zoomImage(isSliderClicked:boolean)
{

 if(isSliderClicked)
  this.zoomScale=Number(this.zoomSlider?.nativeElement.value);
  this.rotateImage(0);
}
changeOrientation(value:string)
{
if(value=='bottom')
  {
    this.fileBox.nativeElement.classList.remove('flexDirection');
this.fileBox.nativeElement.classList.add('flexDirectionReverse');

  }
  else
  if(value=='top')
    {
      this.fileBox.nativeElement.classList.remove('flexDirectionReverse');
  this.fileBox.nativeElement.classList.add('flexDirection');
    }
}
 rotateImage(angleIndex:number) {
  this.angleMultiplier+=angleIndex;
  if(this.angleMultiplier<0)
    {
      this.angleMultiplier=3;
    }
    else if (this.angleMultiplier>3)
      {
        this.angleMultiplier=0;
      }
  let degree = 90*this.angleMultiplier;

if(this.angleMultiplier==1)
  {
   console.log( this.uploadedImage);
   this.renderer.setStyle(
    this.uploadedImage.nativeElement,
    'transform-origin',
    `top left`
  );
    this.renderer.setStyle(
      this.uploadedImage.nativeElement,
      'transform',
      `rotate(${degree}deg)   translateY(${this.zoomScale*-100}%) scale(${this.zoomScale}) `
    );
  }
  else if(this.angleMultiplier==3)
  {
    this.renderer.setStyle(
      this.uploadedImage.nativeElement,
      'transform-origin',
      `top left`
    );
    this.renderer.setStyle(
      this.uploadedImage.nativeElement,
      'transform',
      `rotate(${degree}deg) translateX(${this.zoomScale*-100}%) scale(${this.zoomScale}) `
    );
  }
  else if(this.angleMultiplier==2)  {
    this.renderer.setStyle(
      this.uploadedImage.nativeElement,
      'transform-origin',
      `top left`
    );
    this.renderer.setStyle(
      this.uploadedImage.nativeElement,
      'transform',
      `rotate(${degree}deg) translateX(${this.zoomScale*-100}%) translateY(${this.zoomScale*-100}%) scale(${this.zoomScale}) `
    );
  }
  else   {
    this.renderer.setStyle(
      this.uploadedImage.nativeElement,
      'transform-origin',
      `top left`
    );
    this.renderer.setStyle(
      this.uploadedImage.nativeElement,
      'transform',
      `rotate(${degree}deg)  scale(${this.zoomScale}) `
    );

  }
    // this.renderer.setStyle(
    //   this.uploadedImage.nativeElement,
    //   'offsetWidth',
    //   `100%`
    // );


 }
  exportExcel() {
    // format the data
    this.summaryData = this.FileService.formatSummaryData(this.blobDetails)
    this.revGridData = this.FileService.formatRevGrid(this.revGridDetails)
    this.symbologyData = this.FileService.formatSymbology(this.blobDetails)

    this.FileService.exportExcel(this.summaryData, this.revGridData, this.symbologyData, this.blobDetails?.FileName);
  }

   // pop up visiabilty
  showAssetInfoDialog() {
    this.visible = true;
  }
  closeAssetInfoDialog() {
    this.visible = false;
  }
  startDragging(e:any, flag:any) {
    console.log(e);
    this.mouseDown = flag;
    this.startX = e.pageX - this.slider.nativeElement.offsetLeft;
    this.scrollLeft = this.slider.nativeElement.scrollLeft;


    this.startY = e.pageY - this.slider.nativeElement.offsetTop;
    this.scrollTop = this.slider.nativeElement.scrollTop;
  }
  stopDragging() {
    this.mouseDown = false;
  }
  moveEvent(e:any) {
    e.preventDefault();
    if (!this.mouseDown) {
      return;
    }
    const x = e.pageX - this.slider.nativeElement.offsetLeft;
    const scrollX = x - this.startX;
    this.slider.nativeElement.scrollLeft = this.scrollLeft - scrollX;

    const y = e.pageY - this.slider.nativeElement.offsetTop;
    const scrollY = y - this.startY;
    this.slider.nativeElement.scrollTop = this.scrollTop - scrollY;

  }

  isFreeTrial(): boolean {
    return this.currentUserRole?.Role === 'Guest';
  }

  dummyData = [
    { id: '4', drawing_number: '1234567', tag_type: 'tag', equipment_class: 'Circle', description: '001', commodity_code: '001', sequence_number: '001' },
    { id: '4', drawing_number: '8901234', tag_type: 'tag', equipment_class: 'Rectangle', description: '002', commodity_code: '002', sequence_number: '002' },
    { id: '5', drawing_number: '5678901', tag_type: 'Text Tag', equipment_class: '003', description: '003', commodity_code: '003', sequence_number: '003' },
    { id: '6', drawing_number: '2345678', tag_type: 'Rectangle Tag', equipment_class: '004', description: '004', commodity_code: '004', sequence_number: '004' },
    { id: '7', drawing_number: '9012345', tag_type: 'tag', equipment_class: '005', description: '005', commodity_code: '005', sequence_number: '005' }
  ];

//   navigateToObject(e:any) {
//

//     console.log(this.uploadedImage.nativeElement.width);
//     console.log(this.uploadedImage.nativeElement.offsetWidth);
//     console.log(this.slider.nativeElement);
//     this.SetZoom(3);
//     this.slider.nativeElement.scrollLeft = this.slider.nativeElement.scrollWidth*.86;

//     this.slider.nativeElement.scrollTop = this.slider.nativeElement.scrollHeight*.7;
//     this.slider.nativeElement.focus();

//   }
// }


// @Directive({ selector: '[focusField]' })
// export class FocusDirective implements OnChanges {

//   @Input('focusField') focusField: any;
//   constructor(@Inject(ElementRef) private element: ElementRef) { }

//   ngOnChanges(changes:any){
//     if(changes.focusField.currentValue){
//       this.element.nativeElement.focus();
//     }
//   }
}


