import { Component, OnInit } from '@angular/core';
import { ProfileComponent } from '../../shared/profile/profile.component';
import { DividerModule } from 'primeng/divider';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedService } from '../../../services/shared.service';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [ProfileComponent, DividerModule, CommonModule],
  templateUrl: './header.component.html',
  styleUrl: './header.component.css'
})
export class HeaderComponent implements OnInit {
  welcomeMessage = 'The optimal solution for extracting relevant metadata from engineering drawings'
  isDashboard: boolean = false;


  constructor(
    private route: ActivatedRoute,
    private sharedService: SharedService,
    private router: Router) { }

  ngOnInit(): void {
    this.isDashboard = this.router.url.indexOf('/home')>=0
    this.sharedService.currentURL.subscribe(url => {
      this.isDashboard = url.indexOf('/home')>=0;
    });
  }

}
