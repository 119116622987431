export interface ApplicationTiles{
    Title:string,
    Description:string,
    url:string,
    AllowedRoles:string[],
    Icon:string
}
export const baseIconURL: string = '/assets/images/Icons/'
export const teamManagementTiles:ApplicationTiles[]=[
    {
      Title:'Manage Clients',
      Description:'Create, manage your clients',
      url:"client",
      AllowedRoles:['Super Admin'],
      Icon:baseIconURL+"Manage_Clients.svg"
    },
    {
      Title:'Invite Users',
      Description:'Invite external users',
      url:"invite-user",
      AllowedRoles:['Super Admin','Admin'],
      Icon:baseIconURL+"Invite_Users.svg"
    },
    {
      Title:'Invite Free Trial',
      Description:'Invite free trial users',
      url:"invite-free-trial",
      AllowedRoles:['Super Admin'],
      Icon:baseIconURL+"Invite_Free_Trial.svg"
    },
    {
      Title:'Assign Roles',
      Description:'Assign roles to users',
      url:"user-role",
      AllowedRoles:['Super Admin','Admin'],
      Icon:baseIconURL+"Assign_Role.svg"
    },
    // {
    //   Title:'Reset Password',
    //   Description:'Reset your password',
    //   url:"reset-password",
    //   AllowedRoles:['Super Admin','Admin', 'Leader', 'Engineer', "Guest"],
    //   Icon:baseIconURL+"Reset_PW.svg"
    // }
  ]