export interface UserRole{
    Role:string,
    Value:string
    Rank:number
}

export const userRoles: UserRole[] = [
    {
        Role: 'Super Admin',
        Value: 'Super Admin',
        Rank: 1
    },
    {
        Role: 'Admin',
        Value: 'Admin',
        Rank: 2
    },
    {
        Role: 'Leader',
        Value: 'Leader',
        Rank: 3
    },
    {
        Role: 'Engineer',
        Value: 'Engineer',
        Rank: 4
    },
    {
        Role: 'Guest',
        Value: 'Guest',
        Rank: 5
    }
];