import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormsModule } from '@angular/forms';
import { FileService } from '../../../../services/file.service';
import { MessageService } from 'primeng/api';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { UserRole } from '../../../../models/user-roles-model';
import { ProfileService } from '../../../../services/profile.service';
import { ToastModule } from 'primeng/toast';

@Component({
  selector: 'app-invite-free-trial',
  templateUrl: './invite-free-trial.component.html',
  standalone: true,
  imports: [CommonModule,FormsModule, ButtonModule,  ToastModule, InputTextModule],
  styleUrls: ['./invite-free-trial.component.css'],
  providers:[MessageService]
})
export class InviteFreeTrialComponent  implements OnInit  {
  clientName: string = '';
  users = [
    { userName: '', userPrincipalName: '', role: '' }
  ];
  freeTrialUsers = [
    { userName: '', userPrincipalName: '', role: 'Guest' }
  ];
  currentUserRole:UserRole|undefined;

  constructor(private fb: FormBuilder,private fileService: FileService,
    private messageService: MessageService,
    private profileService: ProfileService
  ) { }

  ngOnInit(
  ) {
    this.profileService.getLoggedInUserInfo().subscribe(userInfo => {
      this.currentUserRole = userInfo?.currentRole
    })
  }

  isSuperAdmin(): boolean {
    return this.currentUserRole?.Role === 'Super Admin';
  }
  freeTrialSubmit() {
    // Invite users
    console.log("Free Trial Invite", this.freeTrialUsers)
    this.fileService.inviteUser(this.freeTrialUsers).subscribe({
      next: (response) => {
        console.log('Free Trial User Successfully Invited', response);
        this.messageService.addAll([
          { severity: 'success', summary: 'Invite Successful', detail: `${this.freeTrialUsers.length} users invited successfully` }
        ]);
        this.freeTrialUsers = [{ userName: '', userPrincipalName: '', role: 'Guest' }];
      },
      error: (error) => {
        console.error('Error inviting user:', error);
        this.messageService.add({
          severity: 'error',
          summary: 'Invite Failed',
          detail: 'Error inviting free trial users'
        });
      }
    });
  }
  removeUser(index: number) {
    if (this.users.length > 1) {
      this.users.splice(index, 1);
    }
  }
  addFreeTrialUser () {
    this.freeTrialUsers.push({ userName: '', userPrincipalName: '', role: 'Guest' });
  }
  removeFreeTrialUser(index: number) {
    if (this.freeTrialUsers.length > 1) {
      this.freeTrialUsers.splice(index, 1);
    }
  }

}
