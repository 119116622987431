<div *ngIf="showFilters">
  <app-filelist-filter 
    [blobList]="blobList" 
    (filtersChanged)="applyFilters($event)">
  </app-filelist-filter>
</div>

<div class="card ms-3 g-0">
  <div class="filter-icons" *ngIf="showIcons">
    <div class="filter-icon-wrapper">
      <button type="button" ngClass="FilterIcon" (click)="resetFilters()">
        <img [src]="'/assets/images/Icons/Clear.svg'" alt="Reset Filters">
      </button>
    </div>
    <div class="pdf-wrapper">
      <button type="button" ngClass="FilterIcon" (click)="resetFilters()">
        <img [src]="'/assets/images/Icons/Extract_PDF.svg'" alt="Filter 1">
      </button>
    </div>
    <p-toast></p-toast>
    <div class="excel-wrapper">
      <button type="button" ngClass="FilterIcon" (click)="exportExcel()">
        <img [src]="'/assets/images/Icons/Extract_XLS.svg'" alt="Filter 2">
      </button>
    </div>
  </div>

  <div>
    <div class="custom-spinner-overlay" *ngIf="isLoading">
      <p-progressSpinner 
        styleClass="custom-spinner" 
        [style]="{width: '100px', height: '100px'}" 
        [strokeWidth]="'7'">
      </p-progressSpinner>
    </div>
    
    <p-table [value]="filteredBlobList" 
           [(selection)]="selectedFiles" 
           [paginator]="paginator" [rows]="rows" class="custom-table"
           selectionMode="multiple">

    <ng-template pTemplate="header">
      <tr>
        <ng-container *ngIf="showCheckboxes">
          <th>
            <p-tableHeaderCheckbox (click)="selectAll()">
              </p-tableHeaderCheckbox>
          </th> 
        </ng-container>
        <th></th>
        <th pSortableColumn="UploadedOn">
          DATE
          <p-sortIcon field="UploadedOn"></p-sortIcon>
        </th>
        <th pSortableColumn="BatchID">
          BATCH ID
          <p-sortIcon field="BatchID"></p-sortIcon>
        </th>
        <th pSortableColumn="FileName">
          FILE NAME
          <p-sortIcon field="FileName"></p-sortIcon>
        </th>
        <th pSortableColumn="UploadedBy">
          UPLOADED BY
          <p-sortIcon field="UploadedBy"></p-sortIcon>
        </th>
        <th pSortableColumn="FileSizeMB">
          SIZE
          <p-sortIcon field="FileSizeMB"></p-sortIcon>
        </th>
        <th pSortableColumn="FileType">
          TYPE
          <p-sortIcon field="FileType"></p-sortIcon>
        </th>
        <th pSortableColumn="Status">
          STATUS
          <p-sortIcon field="Status"></p-sortIcon>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-blob>
      <tr>
        <ng-container *ngIf="showCheckboxes">
          <td>
            <p-tableCheckbox 
          [value]="blob" 
          (click)="toggleFileSelection(blob)">
        </p-tableCheckbox>
          </td>
        </ng-container>
        <td>
          <button 
              type="button" 
              pButton 
              pRipple 
              ngClass="ViewerIcon"
              (click)="selectfiles(blob)">
              <img [src]="blob.Status!=='Extracted'?'/assets/images/Closed.svg':'/assets/images/Open eye.svg'"  alt="DWG">
          </button>
        </td>
        <td>{{ blob.UploadedOn | date:'dd/MM/yyyy' }}</td>
        <td>{{ blob.BatchID }}</td>
        <td>{{ blob.FileName }}</td>
        <td>{{ blob.UploadedBy }}</td>
        <td>{{ blob.FileSizeMB | number:'1.2-2' }}MB</td>
        <td>{{ blob.FileType }}</td>
        <td>{{ blob.Status }}</td>
      </tr>
    </ng-template>
  </p-table>


  </div>
</div>
