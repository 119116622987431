@if (menuTiles && menuTiles.length) {
  <div class="container my-4">
    <div class="row card-container">
      <div class="col-md-3 mb-4" *ngFor="let item of menuTiles">
        <div class="card shadow-sm">
          <div class="card-body">
            <div>
              <a tabindex="0" (click)="navigate(item.url)">
                <div class="d-flex">
                  <div class="icon-my-team"><img [src]="item.Icon" alt=""></div>
                  <div>
                    <h3 class="card-title">{{ item.Title }}</h3>
                    <p class="card-text">{{ item.Description }}</p>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
} @else {
  <div class="d-flex align-items-center">
    <h1>Need Admin access to view this page!</h1></div>

  <router-outlet></router-outlet>

}
