<div>
  <p-sidebar [(visible)]="sidebarVisible"
   dismissable = "false"
   modal = "false"
   showCloseIcon = "false"
   styleClass = "menuItem"
   closeOnEscape = "false">
      <ng-template pTemplate="header">
          <div class="align-items-center px-4 ">
            <img class="w-100 DREXLogo" tabindex="0" alt="DREX Logo" (click)="moveToHomePage()" src="/assets/images/DREX_Symbol_White.png">
          </div>
      </ng-template>
      <ng-template pTemplate="content" >
        <div class="container h-100  g-0">
        <ul class="menubar h-100">
            <li  *ngFor="let data of menuItems" style="margin-bottom: 20px" > 
                <a  [routerLink]="[data.routeLink]" >
                <span class="text-white label"  ><img [src]="data.icon" alt="Menu Icon" (mouseover)="getMenuIcon_onMouseOver(data)"  alt="" (mouseout)="getMenuIcon_onMouseOut(data)"> {{data.label}} </span>    
                </a>
               
            </li>
            <li  class="menubar-lastchild mt-auto "> 
                <a tabindex="0" (click)="logout()" style="vertical-align: bottom;">
                    <span class="text-white">   <img [src]="signOutMenu.icon" alt="Sign Out Icon"  
                        (mouseover)="getMenuIcon_onMouseOver(signOutMenu)" (mouseout)="getMenuIcon_onMouseOut(signOutMenu)"> {{signOutMenu.label}}
                </span>    
                </a>
               
            </li>
        </ul>
    </div>
      </ng-template>
        <div class="">
      
    </div>
        <ng-template pTemplate="footer" styleClass="pt-0">
            <p-divider layout="horizontal" styleClass="flex md:hidden align-bottom" [align]="'center'">
            </p-divider>
            <div class="flex align-items-center px-3 ">
                  <img class="w-100" alt=""  src="/assets/images/TWS_Data_AI_Logo_W.png">
            </div>
        </ng-template>
    </p-sidebar>
</div>