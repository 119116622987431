<div class="container-fluid">
  <div class="row">
    <div class="col-lg-4 pe-5 g-0">
      <div class="container">
        <div class="row">
          <div class="card m-2 mt-0 ms-3 shadow-sm" style="height: 100px;">
            <div class="row ">
              <div class="container d-flex gap-2">
               <div class="icon-my-team"> <img src="/assets/images/Icons/Menu_Icon_My_Teams_Light.svg"  alt="" > </div>
              
              <div >
                <strong class="text-secondary tile-header">MY TEAM</strong>
                <h5><b>12 Members</b></h5>
              </div>
            </div>
            </div>
            <div class="row">
              <div class="col d-flex p-2 pt-0">
                <div>
                  <img src="/assets/images/TeamImages/Agha.png"  alt=""  style="width: 30px;">
                </div>
                <div>
                  <img src="/assets/images/TeamImages/arjun.png" alt=""  style="width: 30px;">
                </div>
                <div>
                  <img src="/assets/images/TeamImages/Lincy.png"  alt="" style="width: 30px;">
                </div>
                <div>
                  <img src="/assets/images/TeamImages/vinay.png"  alt="" style="width: 30px;">
                </div>
                <div>
                  <img src="/assets/images/TeamImages/mahesh.png"  alt="" style="width: 30px;">
                </div>
                <div>
                  <img src="/assets/images/TeamImages/Utsav.png" alt=""  style="width: 30px;">
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <div class="row">
          <div class="card m-2 ms-3 shadow-sm" style="height: auto;">
            <div class="row">
              <div class="container d-flex gap-2">
                <div  class="icon-analytics" > <img src="/assets/images/Icons/Menu_Icon_Analytics_Light.svg" alt="" > </div>
             
              <div >
                <strong class="text-secondary tile-header">ANALYTICS</strong>
                <h5><b *ngIf="totalExtracted">{{ totalExtracted }} Files Extracted</b></h5>
              </div>
            </div>
            </div>
            <div *ngIf="recentBatches.length===0" style="height: 8rem;" class="d-flex justify-content-center align-items-center"> 
              <h3>{{analyticsCardMessage}}</h3>
            </div>
            <div class="row" *ngFor="let batch of recentBatches">
              <div class="col-12">
                <strong class="text-secondary">Run {{ batch.batch_id }}</strong>
                <p>
                  {{ batch.extracted }}/{{ batch.total }}
                  <span *ngIf="batch.in_progress > 0">
                    Extraction in progress 
                    <span class="loading-animation"></span>
                  </span>
                  <span *ngIf="batch.in_progress === 0">
                    Files ready to review 
                    <span class="tick-mark">✔️</span>
                  </span>
                </p>
              </div>
            </div>

          </div>
        </div>
        
        <div class="row">
          <div class="card m-2 ms-3 shadow-sm" style="height: 110px;">
            <div class="row">
              <div class="col-lg-6 pt-1">
                <h5><b>Uploaded Files</b></h5>
              </div>
              <div class="col-lg-6">
                <!-- <span><img src="\assets\images\Icons\Icon_Upload_files.svg"></span> -->
              </div>
            </div>
            <div class="row">
              <div class="col-4 limit">
                <b>In Progress</b>
              </div>
              <div class="col-8 limit">
                The file has been uploaded and data extraction is underway.
              </div>
              <div class="col-4 limit">
                <b>Extracted</b>
              </div>
              <div class="col-8 limit">
                The data is now ready for review.
              </div>
              <div class="col-4 limit">
                <b>Reviewed</b>
              </div>
              <div class="col-8 limit">
                The file has been reviewed by a team member.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <div class="col-lg-8 g-0 ">
      <app-file-upload></app-file-upload>
    </div>
    
   
  </div>
  <div class="d-flex justify-content-between">
    <div class="row"><div class="col-lg-12 ps-4 recentFiles"> <strong>  Recent Files</strong></div></div>
    <div class="me-3 pb-3">
      <button type="button" ngClass="FilterIcon" (click)="refreshFilter()" class="m-0 p-0">
        <img [src]="'/assets/images/Icons/Refresh.svg'" style="box-shadow: none;" alt="Refresh Filters">
      </button>
    </div>
  </div>
  <div class="row">
    
    <div class="col-lg-12 g-0">
      <app-display-filelist [showFilters]="false" [showIcons]="false" [paginator]="false" [rows]="10" [showCheckboxes]="false"></app-display-filelist>
      <!-- [limit]="limit"  -->
    </div>
  </div>
</div>

  <p-dialog header="Password Reset" [modal]="true" [closable]="false" [(visible)]="isDisplayPasswordReset" [style]="{ width: '30rem' }">
  <app-reset-password></app-reset-password>
</p-dialog>

