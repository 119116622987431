
    <div class="reset-password-box">
      <p>Please enter your old password and choose a new one</p>
      <form [formGroup]="resetPasswordForm" (ngSubmit)="onSubmit()">
        <div class="form-row">
          <div class="form-group">
            <label for="old_password">
              <i class="pi pi-lock"></i> Old Password
            </label>
            <input
              type="password"
              id="old_password"
              formControlName="old_password"
              pInputText
              placeholder="Old Password"
            />
            <div *ngIf="resetPasswordForm.controls['old_password'].invalid && resetPasswordForm.controls['old_password'].touched">
              <small class="error">Old password is required.</small>
            </div>
          </div>
          <div class="form-group">
            <label for="new_password">
              <i class="pi pi-lock"></i> New Password
            </label>
            <input
              type="password"
              id="new_password"
              formControlName="new_password"
              pInputText
              placeholder="New Password"
            />
            <div *ngIf="resetPasswordForm.controls['new_password'].invalid && resetPasswordForm.controls['new_password'].touched">
              <small class="error">New password is required (min 8 characters).</small>
            </div>
          </div>
          <div class="form-group">
            <label for="confirm_new_password">
              <i class="pi pi-lock"></i> Confirm New Password
            </label>
            <input
              type="password"
              id="confirm_new_password"
              formControlName="confirm_new_password"
              pInputText
              placeholder="Confirm New Password"
            />
            <div *ngIf="resetPasswordForm.hasError('passwordMismatch') && resetPasswordForm.controls['confirm_new_password'].touched">
              <small class="error">Passwords do not match.</small>
            </div>
          </div>
        </div>
        <div class="form-actions">
          <button aria-label="Reset Password" pButton type="submit" label="Reset Password" [disabled]="resetPasswordForm.invalid" class="btn btn-dark"></button>
        </div>
      </form>
    </div>
  
  <p-toast></p-toast>