<p-toast></p-toast>
<div class="invite-user-container">
  <!-- Form for Regular Users -->
  <form (ngSubmit)="onSubmit()" #assignUserRole="ngForm">
<div class="container-fluid">
  <div class="row">
    <div class="col-5 filter-item g-0">
      <div class="icon-wrapper">
        <img src="/assets/images/Icons/Group 489.svg" alt="Team Member Icon" style="display: inline-block;" class="filter-icon">
      </div>
      <label for="teamMember">Uploaded By</label>
      <p-multiSelect
        [options]="userList" 
        styleClass="w-100"
        [(ngModel)]="selectedTeamMembers"
        placeholder="" 
        [loading]="userList.length===0"
        optionLabel="FullName" 
        required
        (onChange)="onUserSelect($event)">
      </p-multiSelect>
    </div>
    <div class="col-3 filter-item g-0">
      <div class="icon-wrapper">
        <img src="/assets/images/Icons/Group 489.svg" alt="Team Member Icon" style="display: inline-block;" class="filter-icon">
      </div>
      <label for="teamMember">Roles</label>
      <p-dropdown [options]="rolesList" optionLabel="Name" required [loading]="rolesList.length===0"  [(ngModel)]="selectedRole" (onChange)="onRoleSelect($event)" placeholder="Select a role" required></p-dropdown>
     
    </div>
    <div class="col-2 filter-item g-0">
      <button pButton type="submit" aria-label="Submit" label="Submit" class="btn btn-dark"></button>
    
    </div>
  </div>
  
</div>
  </form>
</div>
