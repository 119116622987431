
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { LogLevel } from "@azure/msal-browser";

export const environment = {
    production: true,
    environment: 'Dev',
    logLevel: LogLevel.Error,
    loginRadius:
    {
        rootURL: "https://api.loginradius.com/identity/v2/",
        apiKey: "9a076ff9-dc54-4e2c-9723-20991c1d7871",
        loginURL:"https://devlogin.datadrex.ai/auth.aspx",
        returnURL: "https://dev.datadrex.ai/%23/home"
    },
    apiConfig: [
    {
        //uri: 'https://app-dev-drex-001.azurewebsites.net/'
        uri: 'https://devapi.datadrex.ai/'


    },
    ],
    hostURL: "https://dev.datadrex.ai/"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

