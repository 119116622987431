<p-toast></p-toast>


<div class="invite-user-container">
  <!-- Form for Regular Users -->
  <form *ngIf="isnotSuperAdmin()" (ngSubmit)="onSubmit()" #inviteUserForm="ngForm">
    <h2>My Teams</h2>
    <p>Invite team members</p>
    <div class="header-row">
      <div class="p-field header-item">
        <div class="label-container">
          <img src="/assets/images/Icons/Icon_viewer.svg" alt="Name Icon" class="custom-icon">
          <label for="userName">Name</label>
        </div>
      </div>
      <div class="p-field header-item">
        <div class="label-container">
          <img src="/assets/images/Icons/Icon_viewer.svg" alt="Email Icon" class="custom-icon">
          <label for="userPrincipalName">Email address</label>
        </div>
      </div>
      <div class="p-field header-item">
        <div class="label-container">
          <img src="/assets/images/Icons/Icon_viewer.svg" alt="Role Icon" class="custom-icon">
          <label for="role">Role</label>
        </div>
      </div>
    </div>
    <div *ngFor="let user of users; let i = index" class="invite-row">
      <div class="p-field">
        <input type="text" id="userName-{{i}}" name="userName-{{i}}" pInputText [(ngModel)]="user.userName" placeholder="Name" required />
      </div>
      <div class="p-field">
        <input type="email" id="userPrincipalName-{{i}}" name="userPrincipalName-{{i}}" pInputText [(ngModel)]="user.userPrincipalName" placeholder="Email address" required />
      </div>
      <div class="p-field">
        <p-dropdown [options]="roles_user" [(ngModel)]="user.role" placeholder="Select a role" name="role-{{i}}" required></p-dropdown>
      </div>
      <div class="p-field p-button-icon-only">
        <button *ngIf="i > 0" aria-label="remove user" (click)="removeUser(i)" type="button" pButton icon="pi pi-minus"></button>
      </div>
    </div>
    <div class="actions">
      <button (click)="addUser()" aria-label="add user"  type="button" pButton icon="pi pi-plus" label="Add User" class="add-user-button"></button>
      <button pButton type="submit" aria-label="Send Invite"  label="Send Invite" [disabled]="!inviteUserForm.form.valid" class="send-invite-button"></button>
    </div>
  </form>

  <!-- Form for Super Admins -->
  <form *ngIf="isSuperAdmin()" (ngSubmit)="onSubmitSuperAdmin()" #superAdminInviteUserForm="ngForm">
    <h2>Invite Users</h2>
    <p>Add team members to clients</p>
    <div class="header-row">
      <div class="p-field header-item">
        <div class="label-container">
          <img src="/assets/images/Icons/Icon_viewer.svg" alt="Name Icon" class="custom-icon">
          <label for="userName">Name</label>
        </div>
      </div>
      <div class="p-field header-item">
        <div class="label-container">
          <img src="/assets/images/Icons/Icon_viewer.svg" alt="Email Icon" class="custom-icon">
          <label for="userPrincipalName">Email address</label>
        </div>
      </div>
      <div class="p-field header-item">
        <div class="label-container">
          <img src="/assets/images/Icons/Icon_viewer.svg" alt="Role Icon" class="custom-icon">
          <label for="role">Role</label>
        </div>
      </div>
      <div class="p-field header-item">
        <div class="label-container">
          <img src="/assets/images/Icons/Icon_viewer.svg" alt="Client Icon" class="custom-icon">
          <label for="client">Client</label>
        </div>
      </div>
    </div>
    <div *ngFor="let user of users_super_admin; let i = index" class="invite-row">
      <div class="p-field">
        <input type="text" id="userName-{{i}}" name="userName-{{i}}" pInputText [(ngModel)]="user.userName" placeholder="Name" required />
      </div>
      <div class="p-field">
        <input type="email" id="userPrincipalName-{{i}}" name="userPrincipalName-{{i}}" pInputText [(ngModel)]="user.userPrincipalName" placeholder="Email address" required />
      </div>
      <div class="p-field">
        <p-dropdown [options]="roles_super_admin" [(ngModel)]="user.role" placeholder="Select a role" name="role-{{i}}" required></p-dropdown>
      </div>
      <div class="p-field">
        <p-dropdown [options]="clientsDropdownOptions" [(ngModel)]="user.client" placeholder="Select a client" name="client-{{i}}" required></p-dropdown>
      </div>
      <div class="p-field p-button-icon-only">
        <button *ngIf="i > 0" aria-label="Remove User"  (click)="removeUserSuperAdmin(i)" type="button" pButton icon="pi pi-minus"></button>
      </div>
    </div>
    <div class="actions">
      <button (click)="addUserSuperAdmin()" aria-label="Add User" type="button" pButton icon="pi pi-plus" label="Add User" class="add-user-button"></button>
      <button pButton type="submit" aria-label="Send Invite" label="Send Invite" [disabled]="!superAdminInviteUserForm.form.valid" class="send-invite-button"></button>
    </div>
  </form>
</div>
