


<p-toast></p-toast>



<div *ngIf="isSuperAdmin()" class="invite-user-container">
  <h2>Free Trial Users</h2>
  <p>Invite Free Trial Users</p>
  <form (ngSubmit)="freeTrialSubmit()" #inviteFreeTrialUserForm="ngForm">
    <div *ngFor="let user of freeTrialUsers; let i = index" class="invite-row">
      <div class="p-field">
        <div class="label-container">
          <img src="/assets/images/Icons/Icon_viewer.svg" alt="Name Icon" class="custom-icon">
          <label for="userName-{{i}}">Name</label>
        </div>
        <input type="text" id="userName-{{i}}" name="userName-{{i}}" pInputText [(ngModel)]="user.userName" placeholder="Name" required />
      </div>
      <div class="p-field">
        <div class="label-container">
          <img src="/assets/images/Icons/Icon_viewer.svg" alt="Email Icon" class="custom-icon">
          <label for="userPrincipalName-{{i}}">Email address</label>
        </div>
        <input type="email" id="userPrincipalName-{{i}}" name="userPrincipalName-{{i}}" pInputText [(ngModel)]="user.userPrincipalName" placeholder="Email address" required />
      </div>
      <div class="p-field p-button-icon-only">
        <button *ngIf="i > 0" aria-label="Remove User" (click)="removeUser(i)" type="button" pButton icon="pi pi-minus"></button>
      </div>
    </div>
    <div class="actions">
      <button (click)="addFreeTrialUser()" aria-label="Add User" type="button" pButton icon="pi pi-plus" label="Add User" class="btn btn-dark add-user-button"></button>
      <button pButton type="submit" aria-label="Send Invite" label="Send Invite" [disabled]="!inviteFreeTrialUserForm.form.valid" class="btn btn-dark send-invite-button"></button>
    </div>
  </form>
</div>

