import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { DrexOutput } from '../models/drex-output.model';

 
 
@Injectable({
  providedIn: 'root'
})
export class DrexService {
  private baseUrl = environment.apiConfig[0].uri;

  constructor(private http: HttpClient) {}
 
  callDrex(): Observable<any[]> {
    return this.http.get<any[]>(`${this.baseUrl}drex`);
  }

  getOutputData(): Observable<{Output: DrexOutput[]}>{
    return this.http.get<{Output: DrexOutput[]}>(`${this.baseUrl}drexOutput-fetch`);
  } 
}

 