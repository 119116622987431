import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { catchError, Observable, tap, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable()
export class HttpHeaderInterceptor implements HttpInterceptor {

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Clone the request to add new headers
    const modifiedReq = req.clone({
      setHeaders: {
        'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
        //'Custom-Header': 'value'
      }
    });

    // Pass on the modified request
    return next.handle(modifiedReq).pipe(
      tap(() => {
        // You can add code to handle the response here if needed
      }),
      catchError((error: HttpErrorResponse) => {
        if (error.error.message?.ErrorCode === 906 || error.error?.ErrorCode === 906) {
          sessionStorage.clear();
          window.location.replace(`${environment.loginRadius.loginURL}?action=login&return_url=${environment.loginRadius.returnURL}`);

        }
        // Handle errors here
        if (error.status === 401) {
          // Unauthorized error (e.g., token expired)
          // You might want to redirect to a login page or refresh the token
          console.error('Unauthorized access - possibly redirecting to login.');
        } else if (error.status === 403) {

          // Forbidden error
          console.error(error);
        } else {
          // General error
          console.error('An error occurred:', error.message);
        }
        return throwError(() => new Error(error.message));
      })
    );
  }
}
