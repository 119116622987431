import { Injectable, inject } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from "@angular/router";
import { UserRole, userRoles } from "../models/user-roles-model";
import {BehaviorSubject, map, Observable, of} from "rxjs";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { jwtDecode } from "jwt-decode";
import {NgxSpinnerService} from "ngx-spinner";

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  private baseUrl = environment.apiConfig[0].uri;
  // private LRBaseURL = environment.loginRadius.rootURL;
  allowedUserRoles = userRoles;
  userRoles: string[] | undefined = [];
  private userInfo:any
  userJwtToken: BehaviorSubject<any> = new BehaviorSubject(null);
  currentUserRole: UserRole;
  // LoggedInUserRole$: Subject<UserRole> = new Subject<UserRole>();
  currentUserDetails: BehaviorSubject<any> = new BehaviorSubject(null);
  constructor(private router: Router,
    private http: HttpClient, private spinnerService: NgxSpinnerService
  ) { }

  validateAccessToken() {
    this.spinnerService.show()
    return this.http
      .get(this.baseUrl + "validateToken",
        {
        })
      .pipe(
        map((res: any) => {
          this.setJwtToken(res.jwtToken);
          return res;
        })
      );
  }
  navigateToLoginRadius() {
    sessionStorage.clear();
    window.location.replace(`${environment.loginRadius.loginURL}?action=login&return_url=${environment.loginRadius.returnURL}`);
  }
  signOut() {
    return this.http.get<any>(`${this.baseUrl}signout`)
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }
  validateUserRoles(roles: any[]): boolean {
    let isvalidRoles = false;
      if (roles?.length > 0) {
        this.userRoles = roles;
        this.allowedUserRoles?.forEach((x: UserRole) => {
          if (this.userRoles?.includes(x.Value)) {
            // this.LoggedInUserRole$.next(x);
            this.currentUserRole = x;
            isvalidRoles = true;
          }
        });
      }
    if (!isvalidRoles) this.router.navigate(["/unauthorizedaccess"])

    return isvalidRoles;

  }

  isResetPasswordRequired() {
    return this.http.get(`${this.baseUrl}isResetPasswordRequired?email=${this.userInfo.email}`)
  }

  setJwtToken(token: string) {
    this.userJwtToken.next(token);
    sessionStorage.setItem("userToken", token)
    this.setUserDetails()
  }

  getJwtToken() {
    return this.userJwtToken.getValue();
  }

  setUserDetails() {
    const token = this.getJwtToken();
    if(token) {
      const user: any = jwtDecode(token)
      const userInfo = {
        email: user['email'],
        fullName: user['fullName'],
        firstName: user['firstName'],
        roles: user['roles']
      }
      this.spinnerService.hide()
      if(!userInfo.roles?.length) this.router.navigate(["/unauthorizedaccess"])
      this.userInfo = userInfo
      this.currentUserDetails.next(userInfo)
    }
  }


  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    let token: any;
    if (state.url.indexOf('/home') >= 0) {
      token = next.queryParams["token"];
    }
    if(!token) {
      token = sessionStorage.getItem('token');
    }

    if(token) {
      sessionStorage.setItem('token', token);
      const jwtToken = this.getJwtToken();
      if(!jwtToken) {
        this.validateAccessToken().subscribe(data => {
            sessionStorage.setItem('refreshtoken', data.refresh_token);
          },
          error => {
            console.log(error)
            this.signOut();
          });
      }
    }
    if (!token || token == "undefined") {
      this.navigateToLoginRadius();
      return of(false);
    }


    let userInfo = this.userInfo;
    if (userInfo != null) {
      return of(this.validateUserRoles(userInfo.roles));
    }
    else {
      // return this.profileService.getLoggedInUserDetails().pipe(
      //   map((userInfo) => {
      //
      //       sessionStorage.setItem('userInfo', JSON.stringify(userInfo));
      //       return this.validateUserRoles();
      //     }
      //   ));
      return of(true)
    }
    // if (!validRolePresent) this.router.navigate(['unauthorizedaccess'])

  }
}

export const AuthGuard: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> => {
  return inject(AuthenticationService).canActivate(next, state);
}
