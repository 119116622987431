import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { FileService } from '../../../../services/file.service';
import { MessageService } from 'primeng/api';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { ProfileService } from '../../../../services/profile.service';
import { UserRole } from '../../../../models/user-roles-model';
import { DropdownModule } from 'primeng/dropdown';
import { ToastModule } from 'primeng/toast';

@Component({
  selector: 'app-invite-user',
  templateUrl: './invite-user.component.html',
  standalone: true,
  imports: [CommonModule,FormsModule, ButtonModule,DropdownModule, ToastModule,  InputTextModule],
  styleUrls: ['./invite-user.component.css'],
  providers:[MessageService]
})
export class InviteUserComponent  implements OnInit  {
  roles_user = [
    { label: 'Engineer', value: 'Engineer' },
    { label: 'Leader', value: 'Leader' }
  ]
  roles_super_admin = [
    { label: 'Engineer', value: 'Engineer' },
    { label: 'Leader', value: 'Leader' },
    { label: 'Admin', value: 'Admin' }

  ]
  clients: string[] = [];
  clientsDropdownOptions: { label: string, value: string }[] = [];
  users = [
    { userName: '', userPrincipalName: '', role: '' }
  ];
  users_super_admin = [
    { userName: '', userPrincipalName: '', role: '', client: '' }
  ];

  currentUserRole:UserRole | undefined;

  constructor(
    private messageService: MessageService,
    private fileService: FileService,
    private profileService:ProfileService
  ) { }

  ngOnInit() {
    this.profileService.getLoggedInUserInfo().subscribe(userInfo => {
      this.currentUserRole = userInfo?.currentRole
      this.fetchClients()
    })

}

fetchClients() {
  if (this.isSuperAdmin()) {
    this.fileService.getClientList().subscribe({
      next: (clients: string[]) => {
        this.clients = clients;
        this.clientsDropdownOptions = this.clients.map(client => ({ label: client, value: client }));
      },
      error: (error) => {
        console.error('Error fetching client list:', error);
      }
    });
    console.log(this.currentUserRole)
  }
}

  addUser() {
    this.users.push({ userName: '', userPrincipalName: '', role: '' });
  }

  removeUser(index: number) {
    if (this.users.length > 1) {
      this.users.splice(index, 1);
    }
  }

  addUserSuperAdmin() {
    this.users_super_admin.push({ userName: '', userPrincipalName: '', role: '', client: '' });
  }

  removeUserSuperAdmin(index: number) {
    if (this.users_super_admin.length > 1) {
      this.users_super_admin.splice(index, 1);
    }
  }

  onSubmit() {
    this.fileService.inviteUser(this.users).subscribe({
      next: (response) => {
        console.log('Invite Successful', response);
        this.messageService.addAll([
          { severity: 'success', summary: 'Invite Successful', detail: `${this.users.length} users invited successfully` }
        ]);
        this.users = [{ userName: '', userPrincipalName: '', role: '' }];
      },
      error: (error) => {
        console.error('Error inviting user:', error);
        this.messageService.add({
          severity: 'error',
          summary: 'Invite Failed',
          detail: 'Error inviting users'
        });
      }
    });
  }


  onSubmitSuperAdmin() {
    this.fileService.inviteUserSA(this.users_super_admin).subscribe({
      next: (response) => {
        console.log('Invite Successful', response);
        this.messageService.addAll([
          { severity: 'success', summary: 'Invite Successful', detail: `${this.users_super_admin.length} users invited successfully` }
        ]);
        this.users_super_admin = [{ userName: '', userPrincipalName: '', role: '', client: '' }];
      },
      error: (error) => {
        console.error('Error inviting user:', error);
        this.messageService.add({
          severity: 'error',
          summary: 'Invite Failed',
          detail: 'Error inviting users'
        });
      }
    });
  }



  isSuperAdmin(): boolean {
    return this.currentUserRole?.Role === 'Super Admin';
  }
  isnotSuperAdmin(): boolean {
    return this.currentUserRole?.Role!== 'Super Admin';
  }






}
