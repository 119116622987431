import { Component } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { HttpClient } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { ToastModule } from 'primeng/toast';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { PasswordReset } from '../../../../models/password-reset.model';
import { FileService } from '../../../../services/file.service';
import { AuthenticationService } from '../../../../services/auth.service';

@Component({
  selector: 'app-reset-password',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, ToastModule, ButtonModule, InputTextModule],
  templateUrl: './reset-password.component.html',
  styleUrl: './reset-password.component.css',
  providers: [MessageService,AuthenticationService],
})
export class ResetPasswordComponent {
  resetPasswordForm: FormGroup;
  passwords: PasswordReset = {
    old_password: '',
    new_password: ''
  };

  constructor(
    private fb: FormBuilder,
    private http: HttpClient,
    private messageService: MessageService,
    private fileService: FileService,
    private authService:AuthenticationService
  ) {
    this.resetPasswordForm = this.fb.group(
      {
        old_password: ['', Validators.required],
        new_password: ['', [Validators.required, Validators.minLength(8)]],
        confirm_new_password: ['', Validators.required],
      },
      { validators: this.passwordMatchValidator }
    );
  }

  passwordMatchValidator(control: AbstractControl): { [key: string]: boolean } | null {
    const newPassword = control.get('new_password');
    const confirmPassword = control.get('confirm_new_password');
    return newPassword && confirmPassword && newPassword.value !== confirmPassword.value ? { passwordMismatch: true }: null;
  }

  onSubmit() {

    if (this.resetPasswordForm.valid) {
      this.passwords.new_password = this.resetPasswordForm.get('new_password')?.value;
      this.passwords.old_password = this.resetPasswordForm.get('old_password')?.value;
      this.fileService.resetPassword(this.passwords).subscribe({
        next: (response) => {
          console.log('Password Reset Successful', response);
          this.messageService.addAll([
            { severity: 'success', summary: 'Password Reset Successful', detail: `Your password has been successfully reset` }
          ]);
          this.authService.signOut().subscribe(()=>{
            this.authService.navigateToLoginRadius();
          }
          );
          
        },
        error: (error) => {
          console.error('Error resetting passw:', error);
          this.messageService.add({
            severity: 'error',
            summary: 'Reset Failed',
            detail: 'Password Reset Failed'
          });
        }
      }); 

     }
  }



}
