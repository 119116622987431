import { Component, ElementRef, OnInit, inject } from '@angular/core';
import { FileService } from '../../../../services/file.service';
import { DrexService } from '../../../../services/run-drex.service';
import { ButtonModule } from 'primeng/button';
import { FileUploadModule } from 'primeng/fileupload';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { ProgressBar } from 'primeng/progressbar';
import { DividerModule } from 'primeng/divider';
import { ProfileService } from '../../../../services/profile.service';
import { CommonModule } from '@angular/common';
import { AuthenticationService } from '../../../../services/auth.service';

@Component({
  selector: 'app-file-upload',
  standalone: true,
  imports: [
    DividerModule,
    ButtonModule,
    FileUploadModule,
    ToastModule,
    CommonModule
  ],
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.css'],
  providers: [MessageService, ProgressBar, DrexService]
})
export class FileUploadComponent implements OnInit {
  uploadedFiles: any[] = [];
  tableData: any = null;
  currentUser: string;
  isUploadDisable:boolean=false;
  private messageService = inject(MessageService);
  private profileService = inject(ProfileService);
  constructor(private fileUploadService: FileService, private _elementRef: ElementRef, private authService: AuthenticationService) { }

  ngOnInit(): void {
    this.getUserDetails();

  }
  ModifyDOMElement(): void {
    //Do whatever you wish with the DOM element.
    //let row1 = this._elementRef.nativeElement.querySelector(`#myfile`);
    let row = this._elementRef.nativeElement.querySelector(`.p-fileupload-row`);
    row.firstChild.hidden = true;

  }


  getUserDetails() {
    this.profileService.getLoggedInUserInfo().subscribe(userInfo => {
      this.currentUser = userInfo?.displayName || 'Unknown';
    })
  }

  onUploadHandler(event: any) {
    this.uploadedFiles = event.files;
  }

  uploadFileToServer(files: any[]) {
    // if (!this.validRole()) {
    //   this.messageService.add({
    //     severity: 'error',
    //     summary: 'Invalid Role',
    //     detail: 'You are not authorized to upload files'
    //   });
    //   return;
    // }

    if (files.length > 0) {
      const formData = new FormData();
      for (const file of files) {
        formData.append('files', file);
      }
      formData.append('uploadedBy', this.currentUser);
this.isUploadDisable=true;
      this.fileUploadService.uploadFile(formData).subscribe({
        next: (response) => {
          console.log('Upload Successful', response);
          this.messageService.addAll([{
            severity: 'success',
            summary: 'Upload Successful',
            detail: `${files.length} files uploaded successfully`

          },
          {
            severity: 'info',
            summary: 'Running Drex',
            detail: `Check Analytics for updates`
          }]);
          this.uploadedFiles = [];  // Clear the files after upload
          this.isUploadDisable=false;
        },
        error: (error) => {
          console.error('Error uploading file:', error);
          if (error.status === 429) {
              this.messageService.add({
                  severity: 'warn',
                  summary: 'Free Trial Limit Exceeded',
                  detail: 'Please contact support'
              });
          } else if (error.status === 422) {
            const remainingFiles = error.error.remainingFiles || 0;
              this.messageService.add({
                  severity: 'warn',
                  summary: 'Free Trial Limit Approching',
                  detail: `You can only upload ${remainingFiles} more files`
              });
          } else if (error.status === 413) {
            const batchLimit = error.error.max_batch_size
            this.messageService.add({
                severity: 'warn',
                summary: 'Batch size exceeds limit',
                detail: `Please upload a batch smaller than ${batchLimit} MB.`
            });
          } else {
              this.messageService.add({
                  severity: 'error',
                  summary: 'Upload Failed',
                  detail: 'Error uploading files'
              });
          }
          this.isUploadDisable = false;
        }
      });
    }
  }

  chooseFileButton() {
    const fileUploadElement = document.querySelector('.p-fileupload input[type="file"]') as HTMLElement;
    fileUploadElement.click();
  }

  validRole() {
    return !(this.authService.currentUserRole.Role == 'Engineer');

  }
}
