import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FileService } from '../../../../services/file.service';
import { TableModule } from 'primeng/table';
import { CommonModule } from '@angular/common';
import { BlobDetails, RevGridDetails } from '../../../../models/blob-details.model';
import { Router } from '@angular/router';
import { FilelistFilterComponent } from '../filelist-filter/filelist-filter.component';
import { forkJoin } from 'rxjs';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';




@Component({
  selector: 'app-display-filelist',
  templateUrl: './display-filelist.component.html',
  styleUrls: ['./display-filelist.component.css'],
  standalone: true,
  imports: [CommonModule, TableModule, FilelistFilterComponent, ProgressSpinnerModule, ToastModule],
  providers: [MessageService],

})
export class FileListComponent implements OnInit {
  @Input() showFilters: boolean = true;
  @Input() showIcons: boolean = true;
  @Input() paginator: boolean = true;
  @Input() showCheckboxes: boolean = false;
  @Input() rows: number = 10;
  @Input() limit: number | undefined;
  blobList: BlobDetails[] = [];
  filteredBlobList: BlobDetails[] = [];
  selectedFiles: BlobDetails[] = [];
  selectedFileIds: string[] = [];
  allSelected: boolean = false;
  isLoading: boolean = false;

  fileDetails: BlobDetails[] = [];
  revGridDetails: RevGridDetails[][] = [];

  summaryData: any[] = [];
  revGridData: any[] = [];
  symbologyData: any[] = [];

  plain_id: string;
  previousBlobList: BlobDetails[] = [];

  @ViewChild(FilelistFilterComponent) filelistFilterComponent: FilelistFilterComponent;

  constructor(
    private fileService: FileService,
    private router: Router,
    private messageService: MessageService) {}

  ngOnInit(): void {
    this.refreshFileList(this.limit);
    this.fileService.fileUploadedSource.subscribe(() => {
      console.log('File upload event received');
      this.refreshFileList(this.limit);
    });

    // this.fileService.pollFileStatus(30000).subscribe({
    //   next: (data) => {
    //     console.log("POLL FILE STATUS")
    //     if (!this.areBlobListsEqual(this.blobList, data.blobs)) {
    //       this.blobList = data.blobs;
    //       this.filteredBlobList = [...this.blobList]; 
    //     } 
    //   },
    //   error: (error) => {
    //     console.error('Error polling file status', error);
    //   }
    // });
  }

  getFilename(filename: string): string {
    const lastDotIndex = filename.lastIndexOf('.');
    if (lastDotIndex === -1) return filename; // No extension found
    return filename.substring(0, lastDotIndex);
  }

  selectfiles(file: any) {
    this.router.navigate([`viewer/${this.getFilename(file.FileID)}`]);
  }

   // Function to handle checkbox selection
   toggleFileSelection(blob: BlobDetails) {
    const index = this.selectedFileIds.indexOf(blob.FileID.replace(/\.[^/.]+$/, ""));
    if (index === -1) {
      // If the file ID is not in the list, add it
      this.selectedFileIds.push(blob.FileID.replace(/\.[^/.]+$/, ""));
    } else {
      // If the file ID is already in the list, remove it
      this.selectedFileIds.splice(index, 1);
    }
    console.log("SELECTED IDS", this.selectedFileIds)

  }

  refreshFileList(limit?: number) {
    // console.log("limit:", limit)
    this.fileService.getBlobs(limit).subscribe({
      next: (response: { blobs: BlobDetails[] }) => {
        this.blobList = response.blobs;
        this.filteredBlobList = [...this.blobList]; // Set initial filtered list to full blob list
      },
      error: (error) => {
        console.error('Error fetching file list', error);
      }
    });
  }

  applyFilters(filters: any) {
    this.filteredBlobList = this.blobList.filter(blob => {
      return Object.keys(filters).every(key => {
        if (!filters[key] || filters[key].length === 0) return true;
        switch (key) {
          case 'teamMember':
            return filters[key].includes(blob.UploadedBy);
          case 'status':
            return filters[key].includes(blob.Status);
          case 'fileType':
            return filters[key].includes(blob.FileType);
          case 'dateFrom':
            return new Date(blob.UploadedOn) >= new Date(filters[key]);
          case 'dateTo':
            return new Date(blob.UploadedOn) <= new Date(filters[key]);
          case 'fileSizeMore':
            return blob.FileSizeMB >= parseFloat(filters[key]);
          case 'fileSizeLess':
            return blob.FileSizeMB <= parseFloat(filters[key]);
          default:
            return true;
        }
      });
    });
  }

  resetFilters() {
    this.filelistFilterComponent.resetAllFilters();
    this.filteredBlobList = [...this.blobList];
  }

  private areBlobListsEqual(list1: BlobDetails[], list2: BlobDetails[]): boolean {
    if (list1.length !== list2.length) return false;

    for (let i = 0; i < list1.length; i++) {
      if (list1[i].FileID !== list2[i].FileID ||
          list1[i].Status !== list2[i].Status) {
        return false;
      }
    }
    return true;
  }

  getMultipleBlobs(files: any): Observable<any> {
    return this.fileService.fetchMultipleBlobs(files).pipe(
      tap((data) => {
        this.fileDetails = data;
        this.fileDetails.forEach((file) => {
          this.summaryData.push(...this.fileService.formatSummaryData(file));
          this.symbologyData.push(...this.fileService.formatSymbology(file));
        });
      })
    );
  }

  getMultipleRevGrids(files: any): Observable<any> {
    return this.fileService.fetchMultipleRevGrids(files).pipe(
      tap((data) => {
        this.revGridDetails = data;
        this.revGridDetails.forEach((fileArray) => {
          this.revGridData.push(...this.fileService.formatRevGrid(fileArray));
        });
      })
    );
  }
  

  
exportExcel() {
  if (this.selectedFileIds.length === 0) {
    this.messageService.add({severity:'warn', summary:'Warning', detail:'Please select files before exporting.'});
    return;
  }

  this.isLoading = true; 
  forkJoin([
    this.getMultipleBlobs(this.selectedFileIds),
    this.getMultipleRevGrids(this.selectedFileIds)
  ]).subscribe({
    next: () => {
      this.fileService.exportExcel(this.summaryData, this.revGridData, this.symbologyData, "extracted");
      this.isLoading = false;
      this.fileDetails = [];
      this.revGridDetails = [];
      this.summaryData = [];
      this.revGridData = [];
      this.symbologyData = []
  
    },
    error: (error) => {
      console.error('Error during data fetching:', error);
      this.isLoading = false; // Hide spinner even if there's an error
    }
  });
}


selectAll() {
  if (this.allSelected) {
    this.selectedFiles = [];
    this.selectedFileIds = [];
  } else {
    this.selectedFiles = [...this.filteredBlobList];
    this.selectedFileIds = this.filteredBlobList.map(blob => blob.FileID.replace(/\.[^/.]+$/, ""));
  }
  this.allSelected = !this.allSelected;
  console.log("SELECT ALL IDS", this.selectedFileIds);
}
}
