import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ButtonModule } from 'primeng/button';
import { AuthenticationService } from '../../../services/auth.service';

@Component({
  selector: 'app-unauthorized-access',
  templateUrl: './unauthorized-access.component.html',
  styleUrls: ['./unauthorized-access.component.css'],
  standalone: true,
  imports: [ButtonModule]
})
export class UnauthorizedAccessComponent {

  constructor(private router:Router,private authService:AuthenticationService){}

  goBack()
  {
    this.authService.signOut().subscribe(()=>
      {
        this.authService.navigateToLoginRadius();
      }
    )
  }
}