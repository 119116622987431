import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
declare const SwaggerUIBundle: any;
// function HideTopbarPlugin() {
//   // this plugin overrides the Topbar component to return nothing
//   return {
//     components: {
//       Topbar: function() { return null }
//     }
//   }
// }
@Component({
  selector: 'app-swagger-ui',
  standalone: true,
  imports: [],
  templateUrl: './swagger-ui.component.html',
  styleUrl: './swagger-ui.component.css'
})
export class SwaggerUIComponent implements OnInit {


  ngOnInit(): void {
    const ui = SwaggerUIBundle({
      dom_id: '#swagger-ui',
      layout: 'BaseLayout',
      presets: [
        SwaggerUIBundle.presets.apis,
        SwaggerUIBundle.SwaggerUIStandalonePreset
      ],
      url: environment.hostURL+'assets/swagger/drex_api.json',
      docExpansion: 'none',
      operationsSorter: 'alpha'
    });
    console.log(ui);
  }
}
