<div style="display: flex; flex-direction: column; height: 100vh;">
  <div>
    <app-header></app-header>
  </div>
  <div style="display: flex; flex: 1;">
    <!-- Sidebar component -->
    <app-nav-sidebar></app-nav-sidebar>
    <div class="container-fluid" style="margin-left: 200px; margin-top: 140px;">
    <router-outlet></router-outlet>
  </div>
  





  </div>
</div>
