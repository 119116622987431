export interface TagType {
    original: string;
    mapped: string;
    connector?: boolean;
}

export const tagTypes: TagType[] = [
    { original: 'CircleTag', mapped: 'Local Tag' },
    { original: 'PentagonTag', mapped: 'Pentagon Tag', connector: true },
    { original: 'RectangleTag', mapped: 'Rectangle Tag' },
    { original: 'HexagonTag', mapped: 'Hexagon Tag' },
    { original: 'TextTag', mapped: 'Tag' },
    { original: 'LineTag', mapped: 'Piping Tag' },
    { original: 'DCS_Tag', mapped: 'DCS Tag' },
    { original: 'OvalTag', mapped: 'Oval Tag' }
];