import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FileService } from '../../../../services/file.service';
import { MessageService } from 'primeng/api';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { MultiSelectModule } from 'primeng/multiselect';
import { DropdownModule } from 'primeng/dropdown';
import { ProfileService } from '../../../../services/profile.service';
import { ToastModule } from 'primeng/toast';

@Component({
  selector: 'app-user-role-mapping',
  templateUrl: './user-role-mapping.component.html',
  standalone: true,
  imports: [CommonModule,FormsModule, ButtonModule,MultiSelectModule,DropdownModule,ToastModule, ReactiveFormsModule,  InputTextModule],
  styleUrls: ['./user-role-mapping.component.css'],
  providers:[MessageService]
})
export class UserRoleMappingComponent  implements OnInit  {
  selectedRole:string;
  rolesList:any[]=[];
  userList:any[]=[];
  
  selectedTeamMembers: any[] = [];
  teamMembers: any[] = [];
  constructor(private fb: FormBuilder,private fileService: FileService,
    private messageService: MessageService,
    private profileService:ProfileService
  ) { }

  ngOnInit( 
  ) {
    this.getRoles();
    this.getUserList();
  }
  
  onUserSelect(event: any) {
    this.selectedTeamMembers=event.value;
  }
  onRoleSelect(event: any) {

    this.selectedRole=event.value;
  }
  onSubmit(){
    let formData={
      'users':this.selectedTeamMembers,
      'role':this.selectedRole
    }
    this.fileService.assignUsersRoles(formData).subscribe(()=>
    {
        this.selectedTeamMembers=[]
        this.selectedRole='';
        this.messageService.addAll([
          { severity: 'success', summary: 'Role assigned successfully' }
        ]);
    },
    error => {
      console.error('Error inviting user:', error);
      this.messageService.add({
        severity: 'error',
        summary: 'Failed to assign role'});
      });

  }

  getRoles()
  {
    this.profileService.getRoles().subscribe(res=>
      {
        this.rolesList=res;
      });
  }
  getUserList()
  {
    this.profileService.getUserList().subscribe(res=>
      {
        this.userList=res;
        
      });
  }
  

}
