import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideRouter } from '@angular/router';
import { provideAnimations } from '@angular/platform-browser/animations';
import { routes } from './app.routes';
import { BrowserModule } from '@angular/platform-browser';
import { provideHttpClient, withInterceptorsFromDi, HTTP_INTERCEPTORS, withFetch } from '@angular/common/http';
import { provideNoopAnimations } from '@angular/platform-browser/animations';
import { ButtonModule } from 'primeng/button';
import { DividerModule } from 'primeng/divider';
import { SplitterModule } from 'primeng/splitter';
import { InputTextModule } from 'primeng/inputtext';
import { FloatLabelModule } from 'primeng/floatlabel';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { HttpHeaderInterceptor } from './core/interceptor/http-header.interceptor';
import { provideUserIdleConfig } from 'angular-user-idle';
import {NgxSpinnerModule} from "ngx-spinner";

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes), provideHttpClient(), provideAnimations(), provideAnimationsAsync(),
    importProvidersFrom(BrowserModule,ButtonModule,DividerModule,SplitterModule ,
      InputTextModule,FloatLabelModule, NgxSpinnerModule.forRoot()

    ),
    provideNoopAnimations(),
    provideHttpClient(withInterceptorsFromDi(), withFetch()),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpHeaderInterceptor,
      multi: true
  },
    {
        provide: LocationStrategy,
        useClass: HashLocationStrategy
     },
    provideUserIdleConfig({ idle: 600, timeout: 1, ping: 120 })
  ]
};
