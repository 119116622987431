import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileUploadComponent } from './components/pages/shared/upload-files/file-upload.component';
import { RouterLink,RouterOutlet } from '@angular/router';
import { FileListComponent } from './components/pages/shared/display-filelist/display-filelist.component';
import {NgxSpinnerComponent} from "ngx-spinner";

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, RouterOutlet, RouterLink, FileUploadComponent, FileListComponent, NgxSpinnerComponent],

 templateUrl: './app.component.html',
})
export class AppComponent  {
  isIframe = false;
  loginDisplay = false;

  constructor(
  ) {}



}

