import { Component, OnInit, ViewChild  } from '@angular/core';
import { FileUploadComponent } from '../shared/upload-files/file-upload.component';
import { FileListComponent } from '../shared/display-filelist/display-filelist.component';
import { Router } from '@angular/router';
import { FileService } from '../../../services/file.service';
import { BatchStatus } from '../../../models/progress-details.model';
import { CommonModule } from '@angular/common';
import { DialogModule } from 'primeng/dialog';
import { ResetPasswordComponent } from '../../authentication/reset-password/reset-password/reset-password.component';
import {AuthenticationService} from "../../../services/auth.service";
import {ProfileService} from "../../../services/profile.service";
import {NgxSpinnerService} from "ngx-spinner";
@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [FileUploadComponent,CommonModule, FileListComponent,DialogModule,ResetPasswordComponent],
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.css'
})
export class DashboardComponent implements OnInit {
  totalExtracted: number | undefined;
  recentBatches: BatchStatus[] = [];
 // limit: number = 20; // limit of records displayed on the dashboard
 isDisplayPasswordReset=false;

  analyticsCardMessage="...Loading";

  @ViewChild(FileListComponent) fileListComponent: FileListComponent;

  constructor(private router:Router,
    private fileService:FileService, private authService: AuthenticationService, private profileService: ProfileService, private spinnerService: NgxSpinnerService
  ) {

  }
  ngOnInit(): void {
    this.profileService.getLoggedInUserInfo().subscribe(userInfo => {
      if (userInfo != null ) {
        this.spinnerService.show();
          this.authService.isResetPasswordRequired().subscribe((res: any) => {
            this.spinnerService.hide();
            this.isDisplayPasswordReset = res.IsPasswordResetRequired;
            if(!res.IsPasswordResetRequired) {
              this.fetchStatusInfo()
            }
          })
      }
    })
}

  navigate(page:string)
  {
    this.router.navigate([page])
  }

  fetchStatusInfo(){
    this.fileService.fetchTotalExtracted().subscribe(data => {
      if(data.total_extracted==0)
        this.analyticsCardMessage='No File Extracted'
      this.totalExtracted = data.total_extracted;
    });
    this.fileService.fetchBatchStatus().subscribe(data => {
      this.recentBatches = data;
    },
  (error)=>
  {
    console.log(error);
  }
  );
  }

  refreshFilter() {
    this.fetchStatusInfo()
    this.fileListComponent.refreshFileList();
  }

}
