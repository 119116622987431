import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, interval, Subject, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { BlobDetails,OutputDetails,RevGridDetails} from '../models/blob-details.model';
import { PasswordReset } from '../models/password-reset.model';
import { environment } from '../../environments/environment';
import { switchMap } from 'rxjs/operators';
import { TotalExtracted, BatchStatus } from '../models/progress-details.model';
import { tagTypes } from '../models/tag-detail.model';
import FileSaver from 'file-saver';

@Injectable({
  providedIn: 'root',
})
export class FileService {

    private baseUrl = environment.apiConfig[0].uri;
    fileUploadedSource:Subject<void> = new Subject<void>();
    fileUploaded$ = this.fileUploadedSource.asObservable();

    constructor(private http: HttpClient) {}

    uploadFile(formData: FormData): Observable<any> {
        return this.http.post<any>(`${this.baseUrl}file-upload`, formData).pipe(
            catchError((error) => {
                console.error('Error uploading file:', error);
                return throwError(() => error);
            }),
            map(response => {
                 this.notifyFileUploaded();
                return response;
            })
        );
    }

    notifyFileUploaded() {
        this.fileUploadedSource.next();
      }

    getBlobs(limit?: number): Observable<{blobs: BlobDetails[]}>{
     let params = new HttpParams();
     if (limit) {
      params = params.set('limit', limit.toString());
      }
      return this.http.get<{ blobs: BlobDetails[] }>(`${this.baseUrl}bloblist-fetch`, { params });
    }

    pollFileStatus(intervalTime: number, limit?: number): Observable<{blobs: BlobDetails[]}>{
        return interval(intervalTime).pipe(
            switchMap(()=> this.getBlobs(limit))
        )
    }
    
    fetchImage(fileID: string){
        return this.http.get(`${this.baseUrl}display/${fileID}`, { responseType: 'blob' });
    }
    fetchProcessedImage(fileID: string){
        return this.http.get(`${this.baseUrl}processed-img-fetch/${fileID}`, { responseType: 'blob' });
    }

    fetchBlobData(fileID: string): Observable<BlobDetails> {
        return this.http.get<BlobDetails>(`${this.baseUrl}blob-data-fetch/${fileID}`);
    }

    fetchRevGrid(fileID: string): Observable<RevGridDetails[]>{
        return this.http.get<RevGridDetails[]>(`${this.baseUrl}drexRevGrid-fetch/${fileID}`)
    }

    fetchTotalExtracted(): Observable<TotalExtracted>{
        return this.http.get<TotalExtracted>(`${this.baseUrl}total-extracted`)
    }

    fetchBatchStatus(): Observable<BatchStatus[]>{
        return this.http.get<BatchStatus[]>(`${this.baseUrl}recent-batches`)
    }
    
    inviteUser(users: { userName: string, userPrincipalName: string, role: string }[]): Observable<any> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.http.post<any>(`${this.baseUrl}invite-user-same-client`, JSON.stringify(users), { headers }).pipe(
          catchError((error: HttpErrorResponse) => {
            if (error.error instanceof ErrorEvent) {
              console.error('An error occurred:', error.error.message);
            } else {
              console.error(
                `Backend returned code ${error.status}, ` +
                `body was: ${error.error}`);
            }
            return throwError('Something bad happened; please try again later.');
          })
        );
    }

    inviteUserSA(users: { userName: string, userPrincipalName: string, role: string, client: string }[]): Observable<any> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.http.post<any>(`${this.baseUrl}invite-user-custom-client`, JSON.stringify(users), { headers }).pipe(
          catchError((error: HttpErrorResponse) => {
            if (error.error instanceof ErrorEvent) {
              console.error('An error occurred:', error.error.message);
            } else {
              console.error(
                `Backend returned code ${error.status}, ` +
                `body was: ${error.error}`);
            }
            return throwError('Something bad happened; please try again later.');
          })
        );
    }

    resetPassword(passwordReset: PasswordReset): Observable<any> {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
      });
  
      return this.http.post<any>(`${this.baseUrl}resetPassword`, JSON.stringify(passwordReset), { headers }).pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.error instanceof ErrorEvent) {
            console.error('An error occurred:', error.error.message);
          } else {
            console.error(
              `Backend returned code ${error.status}, ` +
              `body was: ${error.error}`);
          }
          return throwError(() => error)
        })
      );
    }
  
    createClient(formData: FormData): Observable<any> {
        console.log('Sending request to create groups', formData); // Debug log
        return this.http.post<any>(`${this.baseUrl}create-client`, formData);
      }

    getUserRole(): Observable<string> {
        return this.http.get<string>(`${this.baseUrl}roles-fetch`);
    }

    getClientList(): Observable<string[]> {
        return this.http.get<string[]>(`${this.baseUrl}fetch-clients`);
      }
    
    getTeamList(): Observable<any>{
      return this.http.get<any>(`${this.baseUrl}team-members-fetch`)
    }

    assignUsersRoles(formData: any): Observable<any>{
      return this.http.post<any>(`${this.baseUrl}assignUsersRole`,formData)
    }

    fetchMultipleBlobs(fileIds: string[]): Observable<BlobDetails[]> {
      let params = new HttpParams();
      fileIds.forEach(fileId => {
        params = params.append('file_ids', fileId);
      });
    
      return this.http.get<any>(`${this.baseUrl}multiple-blobs-fetch`, { params });
    }

    fetchMultipleRevGrids(fileIds: string[]): Observable<RevGridDetails[][]> {
      let params = new HttpParams();
      fileIds.forEach(fileId => {
        params = params.append('file_ids', fileId);
      });
    
      return this.http.get<any>(`${this.baseUrl}multiple-revGrid-fetch`, { params });
    }

    formatSummaryData(blobDetails: BlobDetails | null): any[] {
      let summaryData: any[] = [];
  
      if (blobDetails == null) {
        // no data was extracted
        return summaryData;
      }
  
      if (blobDetails.Output === undefined) {
        // basic data
        const line = {
          file_name: blobDetails.FileName,
          file_id: blobDetails.FileID,
          file_size: blobDetails.FileSizeMB + " mb",
          file_type: blobDetails.FileType,
          uploader: blobDetails.UploadedBy,
          uploaded_date: blobDetails.UploadedOn,
          title: "N/A",
          revision_number: "N/A",
          drawing_number: "N/A",
          project_number: "N/A",
          tag: "N/A"
        };
        summaryData.push(line);
        return summaryData;
      }
  
      if (blobDetails?.Output?.TextTag === null && blobDetails?.Output?.CircleTag === null && blobDetails?.Output?.PentagonTag === null && blobDetails?.Output?.HexagonTag === null
        && blobDetails?.Output?.LineTag === null && blobDetails?.Output?.OvalTag === null) {
        // no tags were extracted
        let line = {
          file_name: blobDetails.FileName,
          file_id: blobDetails.FileID,
          file_size: blobDetails.FileSizeMB + " mb",
          file_type: blobDetails.FileType,
          uploader: blobDetails.UploadedBy,
          uploaded_date: blobDetails.UploadedOn,
          title: blobDetails.Output.Title,
          revision_number: blobDetails.Output.Revnum,
          drawing_number: blobDetails.Output.Drawnum,
          project_number: blobDetails.Output.Projectnum,
          dept: blobDetails.Output.Dept,
          facility: blobDetails.Output.Facility,
          plant: blobDetails.Output.Plant,
          unit: blobDetails.Output.Unit,
          tag: "N/A"
        };
        summaryData.push(line);
        return summaryData;
      }
  
      const output: OutputDetails = blobDetails.Output;
  
      // List of all TagDetails arrays in OutputDetails
      const tagArrays: (keyof OutputDetails)[] = [
        'CircleTag',
        'RectangleTag',
        'PentagonTag',
        'HexagonTag',
        'TextTag',
        'LineTag',
        'OvalTag',
        'DCS_Tag'
      ];
  
      // Loop over each property
      for (const key of tagArrays) {
        const tagArray = output[key];
        if (Array.isArray(tagArray)) {
          for (const tagDetail of tagArray) {
            let type: any = key;
            type = this.mapTag(type, tagDetail);
            let line = {
              file_name: blobDetails.FileName,
              file_id: blobDetails.FileID,
              file_size: blobDetails.FileSizeMB + " mb",
              file_type: blobDetails.FileType,
              uploader: blobDetails.UploadedBy,
              uploaded_date: blobDetails.UploadedOn,
              title: blobDetails.Output.Title,
              revision_number: blobDetails.Output.Revnum,
              drawing_number: blobDetails.Output.Drawnum,
              project_number: blobDetails.Output.Projectnum,
              dept: blobDetails.Output.Dept,
              facility: blobDetails.Output.Facility,
              plant: blobDetails.Output.Plant,
              unit: blobDetails.Output.Unit,
              type: type,
              equipment_class: tagDetail[2],
              description: tagDetail[3],
              commodity_code: tagDetail[0],
              sequence_number: tagDetail[1]
            };
            summaryData.push(line);
          }
        }
      }
  
      summaryData = this.sortTable(summaryData);
      return summaryData;
    }
  
    sortTable(table: any[]): any[] {
      return table.sort((a, b) => {
        const aPopulatedCount = this.countPopulatedFields(a);
        const bPopulatedCount = this.countPopulatedFields(b);
        return bPopulatedCount - aPopulatedCount;
      });
    }
  
    countPopulatedFields(dataObject: any): number {
      let count = 0;
      for (const key in dataObject) {
        if (dataObject[key]) {
          count++;
        }
      }
      return count;
    }
  
    mapTag(type: string, tagDetail: any[]): string {
      const tagType = tagTypes.find(t => t.original === type);
      if (tagType) {
        type = tagType.mapped;
        if (tagType.connector) {
          tagDetail[3] = 'Connector';
        }
      }
      return type;
    }


    formatRevGrid(revGridDetails: RevGridDetails[]): any[] {
      let revGridData: any[] = [];
      
      if (revGridDetails.length > 0 && revGridDetails[0].Approved == null && revGridDetails[0].By == null && revGridDetails[0].Date == null &&
          revGridDetails[0].Description == null && revGridDetails[0].Eng == null && revGridDetails[0].Project == null &&
          revGridDetails[0].Rev == null) {
        let line = {
          file_name: "N/A",
          file_id: "N/A",
          revison_number: "N/A",
          date: "N/A",
          revision_purpose: "N/A",
          drawn_by: "N/A",
          checked_by: "N/A",
          eng: "N/A",
          approved_by: "N/A",
          project_number: "N/A",
        };
        revGridData.push(line);
        return revGridData;
      }
  
      revGridDetails.forEach(item => {
        let line = {
          file_name: item.FileName,
          file_id: item.FileID,
          revison_number: item.Rev,
          date: item.Date,
          revision_purpose: item.Description,
          drawn_by: item.By,
          checked_by: item.Checked,
          eng: item.Eng,
          approved_by: item.Approved,
          project_number: item.Project
        };
        revGridData.push(line);
      });
  
      return revGridData;
    }

    formatSymbology(blobDetails: BlobDetails | null): any[] {
      let symbologyData: any[] = [];
  
      if (blobDetails?.Output?.Pump == null && blobDetails?.Output?.Valve == null) {
        let line = {
          file_name: blobDetails?.FileName,
          file_id: blobDetails?.FileID,
          pump: "N/A",
          valve: "N/A"
        };
        symbologyData.push(line);
        return symbologyData;
      }
  
      let line = {
        file_name: blobDetails?.FileName,
        file_id: blobDetails?.FileID,
        pump: blobDetails?.Output?.Pump,
        valve: blobDetails?.Output?.Valve
      };
      symbologyData.push(line);
  
      return symbologyData;
    }

    saveAsExcelFile(buffer: any, fileName: string): void {
      let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      let EXCEL_EXTENSION = '.xlsx';
      const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
      });
  
      FileSaver.saveAs(data, fileName + '_DREX_EXPORT' + EXCEL_EXTENSION);
    }

    // configure and save excel sheet
    exportExcel(summaryData: any[], revGridData: any[], symbologyData: any[], name: any): void {
  
      if (summaryData.length === 0) {
        console.error("No data to export");
        return;
      }
      if (revGridData.length === 0) {
        console.error("No revgrid data to export.");
      }
      if (symbologyData.length === 0) {
        console.error("No symbology data to export.");
      }
      import("xlsx").then(xlsx => {
        const worksheet1 = xlsx.utils.json_to_sheet(summaryData);
        const worksheet2 = xlsx.utils.json_to_sheet(revGridData);
        const worksheet3 = xlsx.utils.json_to_sheet(symbologyData);
        const workbook = { Sheets: { 'Summary': worksheet1, 'Revision Grid': worksheet2, 'Symbology': worksheet3 }, SheetNames: ['Summary', 'Revision Grid', 'Symbology'] };
        const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelFile(excelBuffer, name ?? "extracted_data");
      });
    }
  
}