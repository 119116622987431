import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormsModule } from '@angular/forms';
import { FileService } from '../../../../services/file.service';
import { MessageService } from 'primeng/api';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';

@Component({
  selector: 'app-client',
  templateUrl: './client.component.html',
  standalone: true,
  imports: [CommonModule,FormsModule, ButtonModule,   InputTextModule],
  styleUrls: ['./client.component.css'],
  providers:[MessageService]
})
export class ClientComponent    {
  clientName: string = '';
  
  constructor(private fb: FormBuilder,private fileService: FileService,
    private messageService: MessageService
  ) { }


  onSubmitGroup() {

    const formData = new FormData();
    formData.append('client_name', this.clientName);
    this.fileService.createClient(formData).subscribe({
      next: (response) => {
        console.log('Group creation successful', response);
        this.messageService.addAll([
          { severity: 'success', summary: 'Group Creation Successful', detail: `Groups created successfully` }
        ]);
        this.clientName = '';
      },
      error: (error) => {
        console.error('Error creating groups:', error);
        this.messageService.add({
          severity: 'error',
          summary: 'Group Creation Failed',
          detail: 'Error creating groups'
        });
      }
    });
  }
 
}
