
    <div class="body-content">
        <h1 >
            <img src="/assets/images/Icons/Error.svg" alt="Error Logo">
            Sorry,
        </h1>
        <h3>You don’t have access.</h3>
        <span>If you believe you should be able to access this page,</span>
        <span>please contact your account manager.</span>
        <p-button label="Back" size="small" (onClick)="goBack()" styleClass="rounded btn btn-primary btn-sm" />
        <div class="footer mt-auto">
            <img class="img-fluid m-auto float-end" alt="TWS Data AI Logo" style="width: 150px;" src="/assets/images/TWS_Data_AI_Logo_C.png">
        </div>
    </div>
