<div class="header">
  <app-profile></app-profile>
  <p-divider *ngIf="isDashboard" layout="vertical" styleClass="flex md:hidden " [align]="'center'">
    
</p-divider>
    <div class="welcome-message" *ngIf="isDashboard">
      <img src="/assets/images/Icons/Icon_Welcome.svg" alt="DREX Logo">
      <h5 class="limit">{{this.welcomeMessage}}</h5>
    </div>
    <div class="logo">
      <img src="/assets/images/DREX_logo.png" alt="DREX Logo">
    </div>
  </div>
  