<div class="container-fluid">
  <div class="row">
    <div class="col-6 filter-item g-0">
      <div class="icon-wrapper">
        <img src="/assets/images/Icons/Group 489.svg" alt="Team Member Icon" style="display: inline-block;" class="filter-icon">
      </div>
      <label for="teamMember">Uploaded By</label>
      <p-multiSelect 
        [options]="teamMembers" 
        styleClass="w-100"
        [(ngModel)]="selectedTeamMembers"
        placeholder="" 
        (onChange)="onMultiSelectChange('teamMember', $event)">
      </p-multiSelect>
    </div>
    <div class="col-6 filter-item">
      <div class="icon-wrapper">
        <img src="/assets/images/Icons/Icon_Calendar.svg" alt="Date Icon" class="filter-icon">
      </div>
      <label for="date">Date</label>
      <p-calendar 
        [(ngModel)]="selectedDateFrom"
        styleClass="w-100"
        placeholder="From" 
        (onSelect)="onDateChange('dateFrom', $event)">
      </p-calendar>
      <span>-</span>
      <p-calendar 
        [(ngModel)]="selectedDateTo"
        styleClass="w-100"
        placeholder="To" 
        (onSelect)="onDateChange('dateTo', $event)">
      </p-calendar>
    </div>
  </div>
    <div class="row">
    <div class="col-3 filter-item g-0">
      <div class="icon-wrapper">
        <img src="/assets/images/Icons/Icon_Status.svg" alt="Status Icon" class="filter-icon">
      </div>
      <label for="status">Status</label>
      <p-multiSelect 
      styleClass="w-100"
        [options]="statuses" 
        [(ngModel)]="selectedStatuses"
        placeholder="" 
        (onChange)="onMultiSelectChange('status', $event)">
      </p-multiSelect>
    </div>
    <div class="col-3 filter-item">
      <div class="icon-wrapper">
        <img src="/assets/images/Icons/Icon_File_Type.svg" alt="File Type Icon" class="filter-icon">
      </div>
      <label for="filetype">File Type</label>
      <p-multiSelect 
        [options]="fileTypes" 
        [(ngModel)]="selectedFileTypes"
        styleClass="w-100"
        placeholder="" 
        (onChange)="onMultiSelectChange('fileType', $event)">
        
      </p-multiSelect>
    </div>
    <div class=" col-6 filter-item">
      <div class="icon-wrapper">
        <img src="/assets/images/Icons/Icon_File_Size.svg" alt="File Size Icon" class="filter-icon">
      </div>
      <label for="filesize">File Size (MB)</label>
      <input pInputText 
        type="number" 
        [(ngModel)]="fileSizeMore"
        placeholder="More than" 
        (input)="onInputChange('fileSizeMore', $event)">
      <input 
        type="number" pInputText 
        [(ngModel)]="fileSizeLess"
        placeholder="Less than" 
        (input)="onInputChange('fileSizeLess', $event)">
    </div>
  </div>
 
  <div class="filter-row filter-submit-row">

    <button class ="submit-button btn btn-dark m-5" (click)="applyFilters()">Submit</button>

  </div>
</div>
