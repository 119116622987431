<div class="page-container">
  <div class="page-header">
    <img class="page-icon" src="/assets/images/Icons/Icon_viewer.svg" alt="Viewer-Icon">
    <h2>Viewer</h2>
  </div>


  <div class="container-fluid toolbar-buttons">
    <span class="flex-fill text-secondary" ><b>FILE NAME: {{ blobDetails?.FileName }}</b></span>


    <!-- Dont need anymore -->
    <!-- <button type="button" class="icon-button rounded orientation-icon" pButton pRipple pTooltip="XLS"
      tooltipPosition="bottom">
      <img src="/assets/images/Icons/Left.svg" alt="">
    </button>
    <button type="button" class="icon-button rounded orientation-icon" pButton pRipple pTooltip="XLS"
      tooltipPosition="bottom">
      <img src="/assets/images/Icons/Right.svg" alt="">
    </button> -->

    <button type="button" class="icon-button rounded orientation-icon" pButton pRipple
      (click)="changeOrientation('top')" pTooltip="Top View" tooltipPosition="bottom">
      <img src="/assets/images/Icons/Upper.svg" alt="">
    </button>
    <button type="button" class="icon-button rounded orientation-icon" pButton pRipple
      (click)="changeOrientation('bottom')" pTooltip="Bottom View" tooltipPosition="bottom">
      <img src="/assets/images/Icons/Bottom.svg" alt="">
    </button>

   
      <button type="button" class="icon-button rounded left-rotate-icon" pButton pRipple (click)="rotateImage(-1)"
        pTooltip="Rotate Left" tooltipPosition="bottom">
        <img src="/assets/images/Icons/Rotate.svg" alt="">
      </button>
      <button type="button" class="icon-button rounded right-rotate-icon" pButton pRipple (click)="rotateImage(1)"
        pTooltip="Rotate Right" tooltipPosition="bottom">
        <img src="/assets/images/Icons/Share.svg" alt="">
        </button>
        <button type="button" class="icon-button rounded excel-download-icon" pButton pRipple (click)="exportExcel()"
          pTooltip="Download File" tooltipPosition="bottom">
          <img src="/assets/images/Icons/Download_small.svg" alt="">
        </button>




  </div>


  <div #FileBox class="file-box p-5 mb-2">
    <div class=" " #ImageBox>

      <div class="container-fluid d-flex justify-content-between pb-2">
        <div class="container d-flex justify-content-start" style="width: 200px">
          <button type="button" class="icon-button rounded zoom-change-icon" pButton pRipple
          (click)="changeZoom(-1)" pTooltip="Zoom-Out" [disabled]="zoomScale===1"
          tooltipPosition="Zoom-Out">
          <img src="/assets/images/Icons/Zoom_less.svg" alt="">
        </button>
        
          <input type="range"  min="1" max="5" width="100" pTooltip="Zoom Image" class="form-range"
            [value]="zoomScale" (change)="zoomImage(true)" #zoomSlider>
            <button type="button" class="icon-button rounded zoom-change-icon" pButton pRipple
          (click)="changeZoom(1)" pTooltip="Zoom-In" [disabled]="zoomScale===5"
          tooltipPosition="Zoom-In">
          <img src="/assets/images/Icons/Zoom_more.svg" alt="">
        </button>
        </div>
        <div class="mr-auto">
          <button type="button" class="icon-button rounded image-navigator-icon" pButton pRipple
            (click)="changeImage(-1)" pTooltip="View Original Image" [disabled]="currentImageIndex===0"
            tooltipPosition="bottom">
            <img src="/assets/images/Icons/Click left.svg" alt="">
          </button>
          <button type="button" class="ml-auto icon-button rounded image-navigator-icon" pButton pRipple
            (click)="changeImage(1)" pTooltip="View Intermediatary Image" [disabled]="currentImageIndex===1"
            tooltipPosition="bottom">
            <img src="/assets/images/Icons/Click right.svg" alt="">
          </button>
        </div>

      </div>
      <div #parent *ngIf="imageUrls &&imageUrls.length>0 && !imageLoading; else skeletonLoader" class="file-display" tabindex="1"  (mousedown) ="startDragging($event, true)" (mouseup) ="stopDragging()" (mouseleave) ="stopDragging()" (mousemove)="moveEvent($event)">
       
        <img #uploadedImage [src]="imageUrls[currentImageIndex]" alt="Selected Image"
          style="width: 100%; height: auto; cursor:pointer ; pointer-events: none;">

      </div>
      <ng-template #skeletonLoader>
        <p-skeleton width="20rem" height="10rem"></p-skeleton>
        <!-- <p>No image selected.</p> -->
      </ng-template>

    </div>
    <div class="metadata">
      <h3 class="heading">Summary</h3>
      <div class="row g-0">
        <!-- Left Column for blobDetails -->
        <div class="col-md-6 card custom-scroll p-2">
          <div class="metadata">
            <h6 class="heading1">Latest Revision</h6>
            <table class="details-container">
              <thead>
                <tr>
                  <th>Title</th>
                  <th>Drawing Number</th>
                  <th>Project Number</th>
                  <th>Revision Number</th>
                  <th>Tag</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                 <td>{{ blobDetails?.Output?.Title ?? 'N/A' }}</td>
                  <td>{{ blobDetails?.Output?.Drawnum ?? 'N/A' }}</td>
                  <td>{{ blobDetails?.Output?.Projectnum ?? 'N/A' }}</td>
                  <td>{{ blobDetails?.Output?.Revnum ?? 'N/A' }}</td>

                  <td class="d-flex">
                    <a tabindex="0" (click)="showAssetInfoDialog()">View Asset Information</a>
                    <!-- Dialog for Asset Information -->
                    <p-dialog header="Asset Information" [modal]="true" [(visible)]="visible"
                      [style]="{ width: '60rem' }">

                      <div class="count-container" *ngIf="tableData && (tableData.length > 0)">
                        <div class="count-item">
                          <img src="/assets/images/Icons/Pump_Icon.svg" alt="Pump Icon">
                          <span class="count-title"><b>Pump Count:</b></span>
                          <span class="count-value">{{ blobDetails?.Output?.Pump ?? 'N/A' }}</span>
                        </div>
                        <div class="count-item">
                          <img src="/assets/images/Icons/Valve_Icon.svg" alt="Valve Icon">
                          <span class="count-title"><b>Valve Count:</b></span>
                          <span class="count-value">{{ blobDetails?.Output?.Valve ?? 'N/A' }}</span>
                        </div>
                      </div>

                      <div *ngIf=" tableData && (tableData.length > 0); else noResult">
                        <p-table [value]="tableData" class="custom-table" styleClass="p-datatable-striped">
                          <ng-template pTemplate="header" let-columns>
                              <tr>
                                <th pSortableColumn="id">
                                  <p-sortIcon field="id"></p-sortIcon>
                                </th>
                                <th>Drawing No.</th>
                                <th pSortableColumn="tag_type">
                                  Type
                                  <p-sortIcon field="tag_type"></p-sortIcon>
                                </th>
                                <th pSortableColumn="equipment_class">
                                  Equipment Class
                                  <p-sortIcon field="equipment_class"></p-sortIcon>
                                </th>
                                <th pSortableColumn="description">
                                  Description
                                  <p-sortIcon field="description"></p-sortIcon>
                                </th>
                                <th pSortableColumn="commodity_code">
                                  Commodity Code
                                  <p-sortIcon field="commodity_code"></p-sortIcon>
                                </th>
                                <th pSortableColumn="sequence_number">
                                  Sequence No.
                                  <p-sortIcon field="sequence_number"></p-sortIcon>
                                </th>
                              </tr>
                              </ng-template>
                              <ng-template pTemplate="body" let-rowData>
                                <tr>
                                  <td *ngFor="let col of columns">{{ rowData[col.field] }}</td>
                                  <!-- Add more cells with data as needed -->
                                </tr>
                              </ng-template>
                         </p-table>
                      </div>

                      <div> 
                        <div *ngIf="isFreeTrial()" class="paywall-box">
                          <p>Please contact <a href="mailto:DREX@thoughtswinsystems.com">DREX&#64;thoughtswinsystems.com</a> for more information</p>
                        </div>
                        <div *ngIf="isFreeTrial() && tableData && (tableData.length > 0)" class="blur-content">
                          <p-table [value]="dummyData" class="custom-table" styleClass="p-datatable-striped">
                            <ng-template pTemplate="body" let-rowData>
                              <tr>
                                <td *ngFor="let col of columns">{{ rowData[col.field] }}</td>
                              </tr>
                            </ng-template>
                          </p-table>
                        </div>
                      </div>
          <ng-template #noResult>
            <div class="no-result-found">
              <h4>No Results Found</h4>
            </div>
          </ng-template>

          </p-dialog>
          </td>
          </tr>
          </tbody>
          </table>
        </div>
      </div>
      <!-- Right Column for revision history -->
      <div class="col-md-6 card custom-scroll p-2">
        <div class="metadata">
          <h6 class="heading1">Revision History</h6>
          <table class="details-container">
            <thead>
              <tr>
                <th>Revison No.</th>
                <th>Date</th>
                <th>Revision Purpose</th>
                <th>Drawn by</th>
                <th>Checked by</th>
                <th>Approved by</th>
                <th>Engineer</th>
                <th>Project No.</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let revGridDetail of revGridDetails;">
                <td>{{ revGridDetail?.Rev ?? 'N/A' }}</td>
                <td>{{ revGridDetail?.Date ?? 'N/A' }}</td>
                <td>{{ revGridDetail?.Description ?? 'N/A' }}</td>
                <td>{{ revGridDetail?.By ?? 'N/A' }}</td>
                <td>{{ revGridDetail?.Checked ?? 'N/A' }}</td>
                <td>{{ revGridDetail?.Approved ?? 'N/A' }}</td>
                <td>{{ revGridDetail?.Eng ?? 'N/A' }}</td>
                <td>{{ revGridDetail?.Project ?? 'N/A' }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

    </div>
  </div>
</div>
</div>

