
<!-- Create Clients Form for Super Admins -->
<div class="create-client-container">
  <h2>Create Client</h2>
  <form (ngSubmit)="onSubmitGroup()" #groupForm="ngForm">
    <div class="form-row">
      <div class="form-group">
        <div class="label-container">
          <img src="/assets/images/Icons/Icon_viewer.svg" alt="Client Name Icon" class="custom-icon">
          <label for="clientName">Client Name</label>
        </div>
        <input type="text" id="clientName" required [(ngModel)]="clientName" name="clientName" pInputText placeholder="Client Name">
      </div>
    </div>
    <div class="actions">
      <button pButton type="submit" aria-label="Create Client" label="Create Client" [disabled]="!groupForm.form.valid" class="btn btn-dark"></button>
    </div>
  </form>
</div>