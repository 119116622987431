import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { FileService } from '../../../../services/file.service';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-display-file',
  templateUrl: './display-file.component.html',
  styleUrls: ['./display-file.component.css'],
  imports: [CommonModule],
  standalone : true
})
export class DisplayFileComponent implements OnInit {

  imageUrl: any;

  constructor(private fileService: FileService, private sanitizer: DomSanitizer) { }
  ngOnInit(): void {
    const fileId = '1dbb0611-f006-4fa4-a2bd-bf4b55a81e83.png';  
    this.fileService.fetchImage(fileId).subscribe(blob => {
      const url = URL.createObjectURL(blob);
      this.imageUrl = this.sanitizer.bypassSecurityTrustUrl(url);
    });
  }
}

