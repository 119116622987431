import { Injectable } from '@angular/core';
import { UserRole, userRoles } from "../models/user-roles-model";
import {
  HttpClient,
  HttpHeaders,
} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {  map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { UserDetails } from '../models/user-details.model';
import {AuthenticationService} from "./auth.service";

@Injectable({
  providedIn: 'root',
})
export class ProfileService {

  private baseUrl = environment.apiConfig[0].uri;
  allowedUserRoles = userRoles;
  private currentUserInfo: BehaviorSubject<any> = new BehaviorSubject(null);
  constructor(private http: HttpClient, private authService: AuthenticationService) {
    this.setLoggedInUserInfo()
  }



  getImage(imageUrl: string): Observable<Blob> {
    return this.http
      .get(imageUrl, {
        responseType: 'blob',
        headers: new HttpHeaders({ 'Content-Type': 'image/jpeg' }),
      })
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }
  getLoggedInUserDetails(): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}getUserInfo`)
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  setLoggedInUserInfo() {
    this.authService.currentUserDetails.subscribe(userInfo => {
      const userDetails: UserDetails = {};
      if (userInfo != null) {
        userDetails.displayName = userInfo.fullName;
        userDetails.email = userInfo.email;
        if (userInfo?.roles.length > 0) {
          const userRoles = new Set(userInfo.roles);
          this.allowedUserRoles?.forEach((x: UserRole) => {
            if (userRoles.has(x.Value)) {
              userDetails.currentRole = x
            }
          });
        }
        this.currentUserInfo.next(userDetails);
      }
    })
  }

  getLoggedInUserInfo(): Observable<UserDetails> {
    return this.currentUserInfo.asObservable()
  }

  getUserRoles(): Observable<any>
  {
    return this.http.get<any>(`${this.baseUrl}getUserRoles`)
    .pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  recordUser(): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}users`, {});
  }
  getUserList()
  {
    return this.http.get<any[]>(`${this.baseUrl}getUserList`);
  }
  getRoles()
  {
    return this.http.get<any[]>(`${this.baseUrl}getRoles`);
  }
  checkPasswordReset()
  {
    return this.http.get<any[]>(`${this.baseUrl}checkPasswordReset`);
  }
}
