import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { FileService } from '../../../services/file.service';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ToastModule } from 'primeng/toast';
import { UserRole } from '../../../models/user-roles-model';
import { ProfileService } from '../../../services/profile.service';

@Component({
  selector: 'app-user-team',
  templateUrl: './user-team.component.html',
  standalone: true,
  imports: [    CommonModule,
    FormsModule,
    InputTextModule,
    ButtonModule,
    ToastModule,
    DropdownModule],
  styleUrls: ['./user-team.component.css'],
  providers: [MessageService]
})
export class UserTeamComponent implements OnInit  {
  inviteUserForm: FormGroup;
  roles_user = [
    { label: 'Engineer', value: 'engineer' },
    { label: 'Leader', value: 'Leader' }
  ]
  roles_super_admin = [
    { label: 'Engineer', value: 'engineer' },
    { label: 'Leader', value: 'Leader' },
    { label: 'Super Admin', value: 'super-admin' }
  ]
  clients: string[] = [];
  clientsDropdownOptions: { label: string, value: string }[] = [];
  users = [
    { userName: '', userPrincipalName: '', role: '' }
  ];
  users_super_admin = [
    { userName: '', userPrincipalName: '', role: '', client: '' }
  ];
  clientName: string = '';
  userRole: string = '';

  freeTrialUsers = [
    { userName: '', userPrincipalName: '', role: 'Guest' }
  ];

  currentUserRole:UserRole|undefined;

  constructor(
    private fb: FormBuilder,
    private messageService: MessageService,
    private fileService: FileService,
    private profileService:ProfileService
  ) { }

  ngOnInit() {
    this.profileService.getLoggedInUserInfo().subscribe(userInfo => {
      this.currentUserRole = userInfo?.currentRole
      this.fetchClients()
    });

}

fetchClients() {
  if (this.isSuperAdmin()) {
    this.fileService.getClientList().subscribe({
      next: (clients: string[]) => {
        this.clients = clients;
        this.clientsDropdownOptions = this.clients.map(client => ({ label: client, value: client }));
      },
      error: (error) => {
        console.error('Error fetching client list:', error);
      }
    });
  }
}

  addUser() {
    this.users.push({ userName: '', userPrincipalName: '', role: '' });
  }

  removeUser(index: number) {
    if (this.users.length > 1) {
      this.users.splice(index, 1);
    }
  }

  addUserSuperAdmin() {
    this.users_super_admin.push({ userName: '', userPrincipalName: '', role: '', client: '' });
  }

  removeUserSuperAdmin(index: number) {
    if (this.users_super_admin.length > 1) {
      this.users_super_admin.splice(index, 1);
    }
  }

  onSubmit() {
    this.fileService.inviteUser(this.users).subscribe({
      next: (response) => {
        console.log('Invite Successful', response);
        this.messageService.addAll([
          { severity: 'success', summary: 'Invite Successful', detail: `${this.users.length} users invited successfully` }
        ]);
        this.users = [{ userName: '', userPrincipalName: '', role: '' }];
      },
      error: (error) => {
        console.error('Error inviting user:', error);
        this.messageService.add({
          severity: 'error',
          summary: 'Invite Failed',
          detail: 'Error inviting users'
        });
      }
    });
  }


  onSubmitSuperAdmin() {
    this.fileService.inviteUserSA(this.users_super_admin).subscribe({
      next: (response) => {
        console.log('Invite Successful', response);
        this.messageService.addAll([
          { severity: 'success', summary: 'Invite Successful', detail: `${this.users_super_admin.length} users invited successfully` }
        ]);
        this.users_super_admin = [{ userName: '', userPrincipalName: '', role: '', client: '' }];
      },
      error: (error) => {
        console.error('Error inviting user:', error);
        this.messageService.add({
          severity: 'error',
          summary: 'Invite Failed',
          detail: 'Error inviting users'
        });
      }
    });
  }

  onSubmitGroup() {

    const formData = new FormData();
    formData.append('client_name', this.clientName);
    this.fileService.createClient(formData).subscribe({
      next: (response) => {
        console.log('Group creation successful', response);
        this.messageService.addAll([
          { severity: 'success', summary: 'Group Creation Successful', detail: `Groups created successfully` }
        ]);
        this.clientName = '';
      },
      error: (error) => {
        console.error('Error creating groups:', error);
        this.messageService.add({
          severity: 'error',
          summary: 'Group Creation Failed',
          detail: 'Error creating groups'
        });
      }
    });
  }

  isSuperAdmin(): boolean {
    return this.currentUserRole?.Role === 'Super Admin';
  }
  isnotSuperAdmin(): boolean {
    return this.currentUserRole?.Role!== 'Super Admin';
  }

  freeTrialSubmit() {
    // Invite users
    console.log("Free Trial Invite", this.freeTrialUsers)
    this.fileService.inviteUser(this.freeTrialUsers).subscribe({
      next: (response) => {
        console.log('Free Trial User Successfully Invited', response);
        this.messageService.addAll([
          { severity: 'success', summary: 'Invite Successful', detail: `${this.freeTrialUsers.length} users invited successfully` }
        ]);
        this.freeTrialUsers = [{ userName: '', userPrincipalName: '', role: 'Guest' }];
      },
      error: (error) => {
        console.error('Error inviting user:', error);
        this.messageService.add({
          severity: 'error',
          summary: 'Invite Failed',
          detail: 'Error inviting free trial users'
        });
      }
    });
  }


  addFreeTrialUser () {
    this.freeTrialUsers.push({ userName: '', userPrincipalName: '', role: 'Guest' });
  }

  removeFreeTrialUser(index: number) {
    if (this.freeTrialUsers.length > 1) {
      this.freeTrialUsers.splice(index, 1);
    }
  }
}
