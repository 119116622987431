<div class="file-upload-container">
  <p-fileUpload 
      name="myfile"
      [files]="uploadedFiles"
      customUpload="true"
      (uploadHandler)="onUploadHandler($event)"
      [multiple]="true"
      showCancelButton="false"
      accept="image/*,.pdf"
      [auto]="false">
    
    <ng-template pTemplate="header">
      <p-toast></p-toast>
    </ng-template>
    
    <ng-template pTemplate="content" let-files>
      <div class="align-content-center">
        <div class="upload-content">
          <div *ngIf="files.length === 0" style="height: 280px;" class="justify-content-center align-content-center">
            <ng-container *ngTemplateOutlet="templateDragDrop"></ng-container>
            <p class="upload-instructions">DRAG and DROP Files Here</p>
          </div>
        </div>
        <div *ngIf="files.length > 0" class="files-display-lower-box">
          <img src="/assets/images/Icon_Upload_DWG.svg" alt="DWG" class="child-item">
          <img src="/assets/images/Icon_Upload_PDF.svg" alt="PDF" class="child-item">
          <img src="/assets/images/Icon_Upload_PNG.svg" alt="PNG"  class="child-item">
          <img tabindex="0" src="/assets/images/Icon_Upload_Plus.svg" alt="Plus" (click)="chooseFileButton()" class="cursor-pointer child-item justify-content-center">
          <span class="align-content-center text-secondary p-2">Upload more</span>
          <div>
            <button aria-label="Upload File" [disabled]="isUploadDisable" type="button" pButton pRipple (click)="uploadFileToServer(files)" label="Upload" class="btn btn-dark m-2"></button>
          </div>
        </div>
      </div>
      <div style="background-color:#00A8E2;" class="pt-2">
        <span class="upload-or text-white">or upload from <img alt="uploadIcon" src="/assets/images/Icons/Upload.svg"></span>
        <div class="upload-buttons justify-content-center pt-2">
          <button aria-label="From Desktop" pButton type="button" label="From Desktop" class="btn btn-dark p-mr-2" (click)="chooseFileButton()"></button>
          <button aria-label="Google Drive" pButton type="button" label="Google Drive" class="btn btn-dark p-mr-2"></button>
          <button aria-label="Azure File Share" pButton type="button" label="Azure File Share" class="btn btn-dark p-mr-2"></button>
        </div>
      </div>
    </ng-template>
    
  </p-fileUpload>
</div>

<ng-template #templateDragDrop>
  <div class="upload-icons">
    <img src="/assets/images/Icon_Upload_DWG.svg" alt="DWG" class="child-item">
    <img src="/assets/images/Icon_Upload_PDF.svg" alt="PDF">
    <img src="/assets/images/Icon_Upload_PNG.svg" alt="PNG">
    <img  tabindex="0" src="/assets/images/Icon_Upload_Plus.svg" alt="Plus" (click)="chooseFileButton()" class="cursor-pointer">
  </div>
</ng-template>
