import { Component } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { SidebarModule } from 'primeng/sidebar';
import { ButtonModule } from 'primeng/button';
import { AvatarModule } from 'primeng/avatar';
import { DividerModule } from 'primeng/divider';
import { CommonModule } from '@angular/common';
import { MenuItems } from '../../../models/menu-item.model';
import { AuthenticationService } from '../../../services/auth.service';
@Component({
  selector: 'app-nav-sidebar',
  standalone: true,
  imports: [
    SidebarModule, ButtonModule, AvatarModule, DividerModule, CommonModule, RouterModule
  ],
  templateUrl: './nav-sidebar.component.html',
  styleUrl: './nav-sidebar.component.css'
})
export class NavSidebarComponent {

  baseIconURL: string = '/assets/images/Icons/';
  signOutMenu: MenuItems = {
    "label": "Sign Out",
    "icon": this.baseIconURL + 'Menu_Icon_Sign_Out_Light.svg',
    "routeLink": ""
  }
  menuItems: MenuItems[] = [
    {
      "label": "View Files",
      "icon": this.baseIconURL + 'Menu_Icon_View_File_Light.svg',
      "routeLink": "/files"
    },
    {
      "label": "My Teams",
      "icon": this.baseIconURL + 'Menu_Icon_My_Teams_Light.svg',
      "routeLink": "/myteam"
    },
    {
      "label": "Team Management",
      "icon": this.baseIconURL + 'Icon_Team_Management_Light.svg',
      "routeLink": "/manage-team"
    },
    {
      "label": "Analytics",
      "icon": this.baseIconURL + 'Menu_Icon_Analytics_Light.svg',
      "routeLink": "/analytics"
    },
    // {
    //   "label":"My Sets",
    //   "icon":this.baseIconURL+'Menu_Icon_My_Sets_Light.svg',
    //   "routeLink":"/mysets"
    // },
    {
      "label": "User Guide",
      "icon": this.baseIconURL + 'Menu_Icon_User_Guide_Light.svg',
      "routeLink": "/userguide"
    }
  ]
  sidebarVisible = true;

  constructor(
    private router: Router,
    private authService: AuthenticationService
  ) { }


  getMenuIcon_onMouseOver(menu: MenuItems) {
    menu.icon = menu.icon.replace('Light', 'Bold');
  }
  getMenuIcon_onMouseOut(menu: MenuItems) {
    menu.icon = menu.icon.replace('Bold', 'Light');
  }
  logout() {
    this.authService.signOut().subscribe(()=>
      {
        this.authService.navigateToLoginRadius();
      }
    )
  }

  moveToHomePage() {
    this.router.navigate(['home']);
  }


}
