import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ApplicationTiles, teamManagementTiles } from '../../../models/application-tiles.model';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { ProfileService } from '../../../services/profile.service';
import { UserRole } from '../../../models/user-roles-model';

@Component({
  selector: 'app-team-management',
  templateUrl: './team-management.component.html',
  standalone: true,
  imports: [CommonModule,RouterOutlet],
  styleUrls: ['./team-management.component.css']
})
export class TeamManagementComponent  implements OnInit  {

menuTiles:ApplicationTiles[] = [];
currentUserRole:UserRole|undefined;
baseIconURL: string = '/assets/images/Icons/';
  constructor(private router: Router,
    private activeRoute: ActivatedRoute,
    private profileService: ProfileService
  ) {

   }

  ngOnInit() {

    this.profileService.getLoggedInUserInfo().subscribe(userInfo => {
      this.currentUserRole=userInfo?.currentRole;
      this.menuTiles=teamManagementTiles.filter(menu=>{
        const role = this.currentUserRole?.Value || '';
        return menu.AllowedRoles.includes(role);
      });
    })
  }

  navigate(url:string)
  {
    this.router.navigate([url],{ relativeTo: this.activeRoute });
  }

}
